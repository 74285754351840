<template>
  <div class="oa-form">
	<van-cell-group>
		<van-field
		v-model="formData.con.username"
		:class="{'more-index':searchshow}"
		required
		clearable
		id="#search"
		input-align="right"
		label="客户名称"
		placeholder="请输入客户名称"
		@input="searHandler"
		/>
		<van-popup v-model="searchshow" class="searpopup">
		<van-list
			v-model="loading"
			:finished="finished"
		>
			<van-cell v-for="item in searchlist" :key="item.id" @click="userval(item)">
				<span v-html="item.html"></span>
			</van-cell>
			
		</van-list>
		</van-popup>
		<van-field
		v-model="formData.con.budgettype"
		required
		readonly
		right-icon=arrow
		input-align="right"
		label="预算类型"
		placeholder="请选择"
		@click="typeShow"
		/>
	<van-action-sheet v-model="budgetshow" class="popup-box" title="请选择预算类型">
		<van-grid :border="false" :gutter="12" :column-num="2">
			<van-grid-item  v-for="item in bugtyarr" :key="item.id" @click="typeitem(item)" :class="{'active':item.name==formData.con.budgettype}">
				{{ item.name }}
			</van-grid-item>
		</van-grid>
	</van-action-sheet>
		<van-field
		v-model="formData.con.cardspecies"
		required
		readonly
		right-icon=arrow
		input-align="right"
		label="卡种所属大类"
		placeholder="请选择"
		@click="cardHandler"
		/>
		<van-action-sheet v-model="cardshow" class="popup-box" title="请选择卡种所属大类">
		<van-grid :border="false" :gutter="12" :column-num="2">
			<van-grid-item  v-for="item in cardarr" :key="item.id" @click="carditem(item)" :class="{'active':item.name==formData.con.cardspecies}">
				{{ item.name }}
			</van-grid-item>
		</van-grid>
	</van-action-sheet>
		<van-row type="flex" justify="space-between" class="formRadio">
			<van-col span="8">卡种是否定制</van-col>
			<van-col span="16">
			<van-radio-group v-model="formData.data.customized_card_kind">
					<van-radio name="1">定制</van-radio>
					<van-radio name="2">不定制</van-radio>
				</van-radio-group>
			</van-col>
		</van-row>
		<van-field
		v-model="formData.con.cardtype"
		required
		readonly
		right-icon=arrow
		input-align="right"
		label="选择卡种"
		v-show="formData.data.customized_card_kind == '2'"
		placeholder="请选择"
		@click="cardTypeHandler"
		/>
		<van-field
		v-model="formData.data.apply_product"
		required
		input-align="right"
		label="申请产品"
		v-show="formData.data.customized_card_kind == '1'"
		placeholder="请输入申请产品"
		/>
		<van-action-sheet v-model="cardisshow" class="popup-box" title="请选择卡种">
		<van-grid :border="false" :gutter="5" :column-num="1">
			<van-grid-item  v-for="item in cardtypearr" :key="item.id" @click="cardtypeitem(item)" :class="{'active':item.name==formData.con.cardtype}">
				{{ item.name }}
			</van-grid-item>
		</van-grid>
	</van-action-sheet>
		<van-field
		v-model="formData.data.unit_price"
		required
		input-align="right"
		type="number"
		label="单价"
		placeholder="请输入单价"
		@blur="priceTwo('unit_price')"
		/>
		<van-field
		v-model="formData.data.num"
		required
		input-align="right"
		type="number"
		label="数量"
		placeholder="请输入数量"
		/>
		<van-field
		v-model="formData.data.purchase_budget"
		required
		input-align="right"
		type="number"
		label="采购预算"
		placeholder="请输入采购预算"
		@blur="priceTwo('purchase_budget')"
		/>
		<van-cell class="celltitle" title="配赠比例/折扣" v-show="formData.data.sales_way == 3" />
		<van-cell class="celltitle" title="配赠比例/折扣" v-show="formData.data.sales_way == 1" value="例：15.00%，表示配赠15%" />
		<van-cell class="celltitle" title="配赠比例/折扣" v-show="formData.data.sales_way == 2" value="例：0.950，表示95折" />
		<van-row class="peizeng">
			<van-col span="12">
			<van-field
				v-model="peizeng"
				readonly
				right-icon=arrow
				@click="peiclick"
				/>
			</van-col>
			<van-col span="12" v-show="formData.data.sales_way != 3">
			<van-field
				v-model="peione"
				right-icon="per"
				v-show="formData.data.sales_way == 1"
				input-align="right"
				placeholder="请输入配赠多少"
				type="number"
				@blur="salesBlur"
				/>
				<van-field
				v-model="rebate"
				v-show="formData.data.sales_way == 2"
				input-align="right"
				type="number"
				placeholder="请输入折扣多少"
				@blur="salesBlur"
				/>
			</van-col>
		</van-row>
		<van-action-sheet
			v-model="peishow"
			:actions="peiactions"
			@select="peiSelect"
		/>
		<van-row type="flex" justify="space-between" class="formRadio">
			<van-col span="10">是否有溢价佣金</van-col>
			<van-col span="14">
			<van-radio-group v-model="formData.data.is_kickback">
					<van-radio name="1">有佣金</van-radio>
					<van-radio name="2">无佣金</van-radio>
				</van-radio-group>
			</van-col>
		</van-row>
		<van-field
		v-model="formData.data.kickback"
		required
		input-align="right"
		v-show="formData.data.is_kickback == '1'"
		type="number"
		label="溢价佣金"
		placeholder="请输入溢价佣金"
		@blur="priceTwo('kickback')"
		/>
		<van-field
		v-model="formData.data.kickback_unit_price"
		required
		input-align="right"
		v-show="formData.data.is_kickback == '1'"
		type="number"
		class="largetxt"
		label="除去溢价佣金后单价"
		placeholder="请输入"
		@blur="priceTwo('kickback_unit_price')"
		/>
		<van-row type="flex" justify="space-between" class="formRadio">
			<van-col span="10">是否符合量价挂钩</van-col>
			<van-col span="14">
			<van-radio-group v-model="formData.data.is_price_indexed">
					<van-radio name="1">符合</van-radio>
					<van-radio name="2">不符合</van-radio>
				</van-radio-group>
			</van-col>
		</van-row>
		
		<van-cell class="celltitle" title="特批内容" />
		<div class="conbox" v-show="formData.data.general_manager_rule.length || formData.data.cso_rule.length || formData.data.ceo_rule.length">
			<span v-for="item in rulearr" :key="item.id" @click="delrule(item)">{{item.audit_rule}}</span>
		</div>
		<van-field
		v-model="specialcon"
		required
		readonly
		right-icon=arrow
		placeholder="请选择"
		@click="speHandler"
		/>
		<van-action-sheet v-model="speshow" class="popup-special" title="请选择特批内容">
		<van-tabs v-model="activeitem" sticky>
			<van-tab title="总经理特批">
				<van-checkbox-group v-model="temparr">
					<van-cell-group>
						<van-cell
						v-for="item in listsleader"
						clickable
						:key="item.id"
						:title="item.audit_rule"
						>
						<van-checkbox
							:name="item.id.toString()"
							shape="square"
							ref="checkboxes"
							slot="right-icon"
						/>
						</van-cell>
					</van-cell-group>
				</van-checkbox-group>
			</van-tab>
			<van-tab title="CSO特批">
				<van-checkbox-group v-model="csoarr">
					<van-cell-group>
						<van-cell
						v-for="item in listscso"
						clickable
						:key="item.id"
						:title="item.audit_rule"
						>
						<van-checkbox
							:name="item.id.toString()"
							shape="square"
							ref="checkboxes"
							slot="right-icon"
						/>
						</van-cell>
					</van-cell-group>
				</van-checkbox-group>
			</van-tab>
			<van-tab title="CEO特批">
				<van-checkbox-group v-model="ceoarr">
					<van-cell-group>
						<van-cell
						v-for="item in listsceo"
						clickable
						:key="item.id"
						:title="item.audit_rule"
						>
						<van-checkbox
							:name="item.id.toString()"
							shape="square"
							ref="checkboxes"
							slot="right-icon"
						/>
						</van-cell>
					</van-cell-group>
				</van-checkbox-group>
			</van-tab>
		</van-tabs>
		<van-tabbar fixed>
			<van-button type="default" @click="rulescanle">取消</van-button>
			<van-button type="info" @click="rulesubmit">确定</van-button>
		</van-tabbar>
		</van-action-sheet>
		<van-cell class="celltitle" title="审批原因" />
		<van-field
		v-model="formData.data.approval_reason"
		maxlength="255"
		required
		type="textarea"
		class="reasonbox"
		placeholder="请输入审批原因"
		/>
	</van-cell-group>
	<div class="btn_box">
		<van-button type="info" @click="formSubmit" block >提交</van-button>
	</div>
  </div>
</template>

<script>
import { Cell, CellGroup, Row, Col , Panel , Tabbar , Button , Tag , Grid, GridItem , Field , RadioGroup, Radio , ActionSheet , Popup , Tab, Tabs, Checkbox, CheckboxGroup, List ,Toast } from 'vant';
import "babel-polyfill";
import { mapState } from 'vuex'
import {sendPost,promptMsg,passMsg} from '../assets/js/api.js'

export default {
  name: 'home',
  components: {
  	[Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Panel.name]: Panel,
    [Tabbar.name]: Tabbar,
    [Button.name]: Button,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [ActionSheet.name]: ActionSheet,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Popup.name]: Popup,
	[Tag.name]: Tag,
	[Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Checkbox.name]: Checkbox,
	[CheckboxGroup.name]: CheckboxGroup,
	[List.name]: List,
	[Toast.name]: Toast
  },
  data() {
  	return {
		formData: {
		  data:{
			  enterprise_name:'',
			  enterprise_id: '',
			  budget_type_id: '',
			  card_kind_category:'',
			  customized_card_kind: '2',
			  apply_product:'',
			  common_card_kind_id:'',
			  unit_price: '',
			  num:'',
			  purchase_budget:'',
			  sales_way:'1',
			  sales_way_val:'',
			  is_kickback:'2',
			  kickback:'',
			  kickback_unit_price:'',
			  is_price_indexed:'2',
			  general_manager_rule:[],
			  cso_rule:[],
			  ceo_rule:[],
			  approval_reason:''
		  },
		  con:{
			  username:'',
			  budgettype:'',
			  cardspecies:'',
			  customized_card_kind:'',
			  cardtype:'',
			  is_kickback:'',
			  is_price_indexed:'',
			  rules:''
		  }
	  	},
		searchshow: false,
		searchlist: [],
      	loading: false,
      	finished: false,
		budgetshow: false,
		bugtyarr:[],
		cardshow: false,
		cardarr:[],
		cardisshow: false,
		cardtypearr:[],
		peizeng: '配赠',
  		peione: '',
  		peishow: false,
  		peiactions: [
  		{ name: '配赠',id: 1 },
        { name: '无配赠无折扣', id:3 },
		{ name: '折扣', id:2 }],
		rebate: '',
	  specialcon: '',
	  speshow: false,
	  activeitem: 0,
	  temparr: [],
	  ceoarr: [],
	  csoarr:[],
	  listspec: []	  
  	}
  },
  computed: {
	listsleader: function(){
		return this.listspec.filter(item=>{
			return item.approval == "2";
		});
	},
	listsceo: function(){
		return this.listspec.filter(item=>{
			return item.approval == "1";
		});
	},
	listscso: function(){
		return this.listspec.filter(item=>{
			return item.approval == "3";
		});
	},
	rulearr: function(){
		return this.listspec.filter(item=>{
			return this.formData.data.ceo_rule.includes(item.id.toString()) || this.formData.data.general_manager_rule.includes(item.id.toString()) || this.formData.data.cso_rule.includes(item.id.toString());
		}); 
	}
  },
  created: function(){
	 sendPost('api/special/sales/apply/auditrulelist').then(res=>{
		let resdata = res.data;
		if(resdata.code == 0 && resdata.data.length > 0){
			this.listspec = resdata.data;
		}
	});
	if(localStorage.getItem('formData')){
		this.formData = JSON.parse(localStorage.getItem('formData'));
		this.initPublic();
		setTimeout(()=>{
			localStorage.removeItem('formData');
		},50);
	}
	if(!localStorage.getItem('formData') && this.$route.query.id){
		sendPost('api/special/sales/apply/detail',{id:this.$route.query.id}).then(res=>{
			let resData = res.data;
			if(resData.code == 0){
				let dataKey = Object.keys(this.formData.data);
				dataKey.map(v=>{
					this.formData.data[v]=resData.data[v];
					if(v=='general_manager_rule'){
						this.formData.data[v]=resData.data[v].split(',');
					}
					if(v=='cso_rule'){
						this.formData.data[v]=resData.data[v].split(',');
					}
					if(v=='ceo_rule'){
						this.formData.data[v]=resData.data[v].split(',');
					}
				});
				this.initPublic();
				this.formData.data.id=resData.data.id;
				this.formData.con.username = resData.data.enterprise_name;
				this.formData.con.budgettype = resData.data.budget_type_name;
				this.formData.con.cardspecies = resData.data.card_kind_category_name;
				this.formData.con.cardtype = resData.data.common_kind_card_name;
			}
		});
	}
  },
  methods: {
	initPublic(){
		sendPost('api/special/sales/apply/budgettypelist').then(res=>{
			let resdata = res.data;
			if(resdata.code == 0){
				this.bugtyarr = resdata.data;
			}
		});
		sendPost('api/special/sales/apply/cardkindcategory',{budget_type_id:this.formData.data.budget_type_id}).then(res=>{
			let resdata = res.data;
			if(resdata.code == 0){
				this.cardarr = resdata.data;
			}
		});
		if(this.formData.data.card_kind_category){
			sendPost('api/special/sales/apply/commoncardkind',{category_id:this.formData.data.card_kind_category}).then(res=>{
				let resdata = res.data;
				if(resdata.code == 0 && resdata.data.length > 0){
					this.cardtypearr = resdata.data;
				}
			});
		}
		this.peione = (this.formData.data.sales_way == 1) ? this.formData.data.sales_way_val : '';
		this.rebate = (this.formData.data.sales_way == 2) ? this.formData.data.sales_way_val : '';
		this.temparr=this.formData.data.general_manager_rule;
		this.ceoarr=this.formData.data.ceo_rule;
		this.csoarr=this.formData.data.cso_rule;
		this.formData.data.sales_way=this.formData.data.sales_way.toString();
		this.formData.data.customized_card_kind=this.formData.data.customized_card_kind.toString();
		this.formData.data.is_kickback=this.formData.data.is_kickback.toString();
		this.formData.data.is_price_indexed=this.formData.data.is_price_indexed.toString();

		if(this.formData.data.sales_way == 1){
			this.peizeng='配赠';
		}
		if(this.formData.data.sales_way == 2){
			this.peizeng='折扣';
		}
		if(this.formData.data.sales_way == 3){
			this.peizeng='无配赠无折扣';
		}
	},
	searHandler(){
		let name = this.formData.con.username;
		name = name.replace(/\s*/g,"");
		sendPost('api/special/sales/apply/myenterprise',{keyword:name}).then(res=>{
			let resdata = res.data;
			if(resdata.code == 0 && resdata.data.length > 0){
				this.searchshow = true;
				let nameStr=new RegExp(name);
				resdata.data.map(v=>{
					v.html = v.text.replace(nameStr,'<strong>'+name+'</strong>');
				});
				this.searchlist = resdata.data;
				this.loading = false;
				this.finished = true;
			}else{
				this.searchshow = false;
				this.formData.data.enterprise_id = '';
				this.formData.data.enterprise_name = '';
			}
		});
	},
	userval(item){
		this.formData.con.username = item.text;
		this.formData.data.enterprise_name = item.text;
		this.formData.data.enterprise_id = item.id;
		this.searchshow = false;
		sendPost('api/special/sales/apply/budgettypelist',{enterprise_id:this.formData.data.enterprise_id}).then(res=>{
			let resdata = res.data;
			if(resdata.code == 0){
				this.bugtyarr = resdata.data;
			}
		});
	},
	typeShow(){
		if(this.bugtyarr.length>0){
			this.budgetshow = true;
		}else{
			promptMsg('请输入正确的客户名称！');
		}
		
	},
	typeitem(item){
		this.budgetshow = false;
		this.formData.con.budgettype = item.name;
		this.formData.data.budget_type_id = item.id;
		this.formData.con.cardspecies='';
		this.formData.data.customized_card_kind='2';
		this.formData.data.apply_product='';
		this.formData.con.cardtype='';
		sendPost('api/special/sales/apply/cardkindcategory',{budget_type_id:item.id,enterprise_id:this.formData.data.enterprise_id}).then(res=>{
			let resdata = res.data;
			if(resdata.code == 0){
				this.cardarr = resdata.data;
			}
		});
	},
	cardHandler(){
		if(this.cardarr.length>0){
			this.cardshow = true;
		}else{
			promptMsg('请选择预算类型！');
		}
	},
	carditem(item){
		this.cardshow = false;
		this.formData.con.cardspecies = item.name;
		this.formData.data.card_kind_category = item.id;
		this.formData.data.customized_card_kind='2';
		this.formData.data.apply_product='';
		this.formData.con.cardtype='';
		sendPost('api/special/sales/apply/commoncardkind',{category_id:item.id}).then(res=>{
			let resdata = res.data;
			if(resdata.code == 0 && resdata.data.length > 0){
				this.cardtypearr = resdata.data;
			}
	  	});
	},
	cardTypeHandler(){
		if(this.cardtypearr.length>0){
			this.cardisshow = true;
		}else{
			promptMsg('请选择卡种所属大类！');
		}
	},
	cardtypeitem(item){
		this.cardisshow = false;
		this.formData.con.cardtype = item.name;
		this.formData.data.common_card_kind_id = item.id;
	},
    peiSelect(item) {
      this.peishow = false;
	  this.peizeng = item.name;
	  this.formData.data.sales_way = item.id;
    },
    peiclick(){
    	this.peishow = true;
	},
	speHandler(){
		this.speshow = true;
	},
	priceTwo(val){
		if(this.formData.data[val]){
			this.formData.data[val]=Number(this.formData.data[val]).toFixed(2);
		}
	},
	salesBlur(){
		if(this.formData.data.sales_way==1){
			let num = Number(this.peione);
			if(num==0 || num>=100){
				promptMsg('请输入正确的配赠比例！');
			}else{
				this.peione=num.toFixed(2);
			}
		}
		if(this.formData.data.sales_way==2){
			let num = Number(this.rebate);
			if(num==0 || num>=1){
				promptMsg('请输入正确的折扣比例！');
			}else{
				this.rebate=num.toFixed(3);
			}
		}
	},
	rulesubmit(e) {
		if(!this.temparr.length && !this.ceoarr.length && !this.csoarr.length){
			promptMsg('至少选择一个特批项！');
		}else{
			this.formData.data.general_manager_rule=this.temparr;
			this.formData.data.cso_rule=this.csoarr;
			this.formData.data.ceo_rule=this.ceoarr;
			this.speshow = false;
		}
		e.preventDefault();
	},
	rulescanle(e) {
		this.speshow = false;
		e.preventDefault();
	},
	delrule(item) {
		let managerIndex=this.formData.data.general_manager_rule.findIndex(v=>v === item.id.toString());
		let ceoIndex=this.formData.data.ceo_rule.findIndex(v=>v === item.id.toString());
		let csoIndex=this.formData.data.cso_rule.findIndex(v=>v === item.id.toString());
		if(managerIndex != -1){
			this.formData.data.general_manager_rule.splice(managerIndex, 1);
		}
		if(ceoIndex != -1){
			this.formData.data.ceo_rule.splice(ceoIndex, 1);
		}
		if(csoIndex != -1){
			this.formData.data.cso_rule.splice(csoIndex, 1);
		}
	},
	formSubmit(e) {
		let formData={};
		let params={};
		let conObj={};
		if(!this.formData.data.enterprise_id){
			promptMsg('请输入正确的客户名称！');
			return;
		}
		if(!this.formData.data.budget_type_id){
			promptMsg('请选择预算类型！');
			return;
		}
		if(!this.formData.data.card_kind_category){
			promptMsg('请选择卡种所属大类！');
			return;
		}
		if(this.formData.data.customized_card_kind == '1' && !this.formData.data.apply_product){
			promptMsg('请输入申请产品！');
			return;
		}
		if(this.formData.data.customized_card_kind == '2' && !this.formData.data.common_card_kind_id){
			promptMsg('请选择卡种！');
			return;
		}
		if(!this.formData.data.unit_price){
			promptMsg('请输入单价！');
			return;
		}
		if(!this.formData.data.num){
			promptMsg('请输入数量！');
			return;
		}
		if(!this.formData.data.purchase_budget){
			promptMsg('请输入采购预算！');
			return;
		}
		if(this.formData.data.sales_way == 1 && !this.peione){
			promptMsg('请输入配赠多少！');
			return;
		}
		if(this.formData.data.sales_way == 2 && !this.rebate){
			promptMsg('请输入折扣多少！');
			return;
		}
		if(this.formData.data.is_kickback == 1 && !this.formData.data.kickback){
			promptMsg('请输入溢价佣金！');
			return;
		}
		if(this.formData.data.is_kickback == 1 && !this.formData.data.kickback_unit_price){
			promptMsg('请输入除去溢价佣金后单价！');
			return;
		}
		if(!this.rulearr.length){
			promptMsg('请选择特批内容！');
			return;
		}else{
			let temp = [];
			this.rulearr.forEach(v=>{
				temp.push(v['audit_rule']);
			});
			this.formData.con.rules = temp.join('；');
		}
		if(!this.formData.data.approval_reason){
			promptMsg('请输入审批原因！');
			return;
		}
		this.formData.con.customized_card_kind = (this.formData.data.customized_card_kind == 1) ? '定制' : '不定制';
		this.formData.con.is_kickback = (this.formData.data.is_kickback == 1) ? '有佣金' : '无佣金';
		this.formData.con.is_price_indexed = (this.formData.data.is_price_indexed == 1) ? '符合' : '不符合';
		this.formData.data.apply_product=(this.formData.data.customized_card_kind == 1) ? this.formData.data.apply_product : '';
		this.formData.data.common_card_kind_id=(this.formData.data.customized_card_kind == 2) ? this.formData.data.common_card_kind_id : '';
		if(this.formData.data.sales_way == 1){
			this.formData.data.sales_way_val = this.peione;
			this.formData.con.sales_way_val=this.peione+'%';
		}
		if(this.formData.data.sales_way == 2){
			this.formData.data.sales_way_val = this.rebate;
			this.formData.con.sales_way_val=this.rebate;
		}
		if(this.formData.data.sales_way == 3){
			this.formData.data.sales_way_val = '';
			this.formData.con.sales_way_val='无';
		}
		localStorage.setItem('formData',JSON.stringify(this.formData));		
		this.$router.push('/form/preview');
	}
  }
}
</script>
<style>
.oa-form{
	background: #F5F6F9;
	min-height:100vh;
}
.oa-form .van-tabbar{
	height:55px;
}
.oa-form .van-button{
	line-height: 40px;
	height:40px;
	font-size:15px;
	margin-top:7px;
}
.oa-form .more-index{
	z-index:9999;
}
.oa-form .searpopup{
	width:100%;
	min-height:100vh;
	margin-top:44Px;
}
.oa-form .van-cell__title{
	color:#666;
}
.oa-form input::-webkit-input-placeholder{
	color:#ccc;
}
.oa-form .van-icon-arrow{
	font-size:14px;
}
.oa-form .searpopup .van-cell{
	padding:8px 15px;
}
.oa-form .searpopup .van-cell:not(:last-child)::after{
	border-bottom:0;
}
.oa-form .searpopup .van-cell strong{
	color:#999;
	font-weight: normal;
}
.oa-form .popup-box .van-action-sheet__content{
	padding:15px 0;
	max-height:355px;
	overflow-y:inherit;
}
.oa-form .popup-special .van-action-sheet__content{
	padding-bottom:15px;
}
.oa-form .popup-special .van-tab--active{
	color:#307DCD;
}
.oa-form .popup-special .van-tabs__line{
	background:#307DCD;
	width:50px!important;
}
.oa-form .popup-special .van-tab__pane{
	padding:12px;
	height:400px;
	padding-bottom:40px;
	overflow-y:auto;
}
body .van-toast{
	width:160px;
}
.oa-form .popup-special .van-cell-group .van-cell{
	border:0.5Px solid #ccc;
	margin-bottom: 5px;
	border-radius: 4px;
}
.oa-form .popup-special .van-button{
	margin:10px;
}
.oa-form .van-checkbox__icon--checked .van-icon{
	background:#307DCD;
}
.oa-form .popup-box .van-grid-item__content{
	border:1Px solid #ccc;
	border-radius:4px;
	padding:10px;
	color:#333;
}
.oa-form .popup-box .van-grid-item:nth-child(2n) .van-grid-item__content{
	margin-right:-1px;
}
.oa-form .popup-box .active .van-grid-item__content{
	border:1Px solid #307DCD;
	color:#307DCD;
	background:url(../assets/img/icon/icon-19.png) #F5F9FD 100% 100% no-repeat;
	background-size:40px 40px;
}
.oa-form .popup-box .van-action-sheet__close{
	font-size:20px;
}
.oa-form .formRadio{
	padding:8px 15px;
	line-height: 24px;
	font-size: 14px;
	border-bottom:0.5Px solid #ebedf0;
}
.oa-form .formRadio .van-radio-group{
	display: flex;
	justify-content:flex-end;
}
.oa-form .formRadio .van-radio__icon{
	display: none;
	
}
.oa-form .van-action-sheet__header{
	font-size:12px;
	color:#666;
}
.oa-form .van-grid-item{
	font-size:14px;
	color:#333;
}
.oa-form .formRadio .van-radio{
	padding:0 5px;
	min-width:46px;
	height:25px;
	line-height: 25px;
	background: #F0F0F0;
	border-radius: 15px;
	font-size: 14px;
	justify-content: center;
	margin-left:15px;
	border:1px solid #F0F0F0;
}
.oa-form .formRadio .van-radio .van-radio__label{
	line-height:19px;
}
.oa-form .formRadio .van-radio[aria-checked="true"]{
	border:1px solid #307DCD;
	background: #F5F9FD;
}
.oa-form .formRadio .van-radio[aria-checked="true"] span{
	color:#307DCD;	
}
.oa-form .van-cell:not(:last-child)::after{
	left:0;
}
.oa-form .popup-special .van-cell:not(:last-child)::after{
	border-bottom:0px;
}
.oa-form .formRadio .van-radio__label{
	margin-left:0;
}
.oa-form .celltitle{
	background: #F5F6F9;
}
.oa-form .celltitle .van-cell__title, .oa-form .celltitle .van-cell__value{
	flex:auto;
	font-size:12px;
	color:#999;
}
.oa-form .peizeng {
	display: flex;
	border-bottom:10px solid #F5F6F9;
}
.oa-form .peizeng > .van-col{
	float:none;
	flex:auto;
}
.oa-form .peizeng > .van-col:nth-child(2){
	border-left:1px solid #ebedf0;
}
.oa-form .van-icon-per{
	line-height:24px;
}
.oa-form .van-icon-per:before{
	content:'%';
	color:#333;
	font-size: 14px;
}
.oa-form .reasonbox textarea{
	height:120px;
}
.oa-form .van-cell--required::before{
	display:none;
}
.oa-form .van-cell--required span,.oa-form .celltitle span{
	position: relative;
}
.oa-form .van-cell--required span::after,.oa-form .celltitle span::after{
	content:'*';
	color:#f44;
	position:absolute;
	right:-7px;
	font-size: 14px;
}
.oa-form .largetxt .van-field__label{
	width:130px;
}
.oa-form .conbox{
	display:flex;
	flex-wrap:wrap;
	padding:12px 7px 0;
	border-bottom:1px solid #E5E5E5;
}
.oa-form .conbox span{
	padding:6px 16px 6px 10px;
	line-height: 20px;
	border-radius: 4px;
	margin:0 5px 10px;
	font-size: 14px;
	color:#333;
	background:url(../assets/img/icon/icon-18.png) #F5F9FD 100% 0 no-repeat;
	background-size:14px 14px;
}
.oa-form .van-button--info{
	background:#307DCD;
  border-color:#307DCD;
}
.oa-form .btn_box{
	background:#fff;
	padding:6px 0 13px;
}

@media only screen and (width: 375px) and (min-height: 690px){
  .oa-form .searpopup{
		margin-top:60Px;
	}
 }
</style>
