<template>	
	<div class="review" :class="{'revbot':detaillCon.status=='3'}">
		<div class="review_top">
			<van-cell size="large" center title="我的特批审批单" :icon="avatar" />
			<van-tag class="review_status" v-if="detaillCon.status==1" plain color="#307DCD">审批中</van-tag>
			<van-tag class="review_status" v-if="detaillCon.status==2" plain color="#4EB240">已通过</van-tag>
			<van-tag class="review_status" v-if="detaillCon.status==3" plain color="#F44545">已拒绝</van-tag>
			<div class="review__txt"  v-if="detaillCon.status==3">
				<van-cell title-class="tit" title="拒绝原因" :value="detaillCon.reject_reason" />
			</div>
		</div>
		
		
		<van-cell-group title="" class="group_bd">
			<van-cell title-class="tit" v-if="detaillCon.status==2" title="审批编号" :value="detaillCon.id" />
			<!-- <van-cell title-class="tit" title="发起人" :value="detaillCon.applicant_name" />
			<van-cell title-class="tit" title="城市" :value="detaillCon.city_name" />
			<van-cell title-class="tit" title="所属部门" :value="detaillCon.department_name" />
			<van-cell title-class="tit" title="总经理" :value="detaillCon.manager_name" /> -->
			<van-cell title-class="tit" title="客户名称" :value="detaillCon.enterprise_name" />
			<van-cell title-class="tit" title="预算类型" :value="detaillCon.budget_type_name" />
			<van-cell title="卡种所属大类" :value="detaillCon.card_kind_category_name" />
			<van-cell title="卡种是否定制" :value="customized" />
			<van-cell title="申请产品" v-if="detaillCon.customized_card_kind==1" :value="detaillCon.apply_product" />
			<van-cell title="选择卡种" v-if="detaillCon.customized_card_kind==2" :value="detaillCon.common_kind_card_name" />
			<van-cell title="单价" :value="'¥'+detaillCon?detaillCon.unit_price:''" />
			<van-cell title="数量" :value="detaillCon.num" />
			<van-cell title="采购预算" :value="'¥'+detaillCon?detaillCon.purchase_budget:''" />
			<van-cell title="配赠比例 /折扣" :value="detaillCon.sales_way_val" />
			<van-cell title-class="tit" title="是否有溢价佣金" :value="kickback" />
			<van-cell title-class="tit" title="溢价佣金" v-if="detaillCon.is_kickback==1" :value="'¥'+detaillCon.kickback" />
			<van-cell title-class="tit" title="除去溢价佣金后单价" v-if="detaillCon.is_kickback==1" :value="'¥'+detaillCon.kickback_unit_price" />
			<van-cell title-class="tit" title="是否符合量价挂钩" :value="is_price_indexed" />
			<van-cell title-class="tit" title="特批内容" :value="detaillCon.rule_content" />
			<van-cell title-class="tit" title="审批原因" :value="detaillCon.approval_reason" />
		</van-cell-group>

	    <div class="review_bottom">
	    	<van-cell-group title="审批流程" size="large">
				<div class="tiemline" v-if="Object.keys(pending_audit).length!=0">
					<img v-if="pending_audit.status=='3'" src="../assets/img/icon/icon-10.png">
					<img v-if="pending_audit.status=='2'" src="../assets/img/icon/icon-09.png">
					<img v-if="pending_audit.status=='1'" src="../assets/img/icon/icon-08.png">
					<van-cell title-class="" :title="pending_audit.audit_user_name +' • '+ pending_audit.status_str" :value="pending_audit.created_at" :icon="pending_audit.audit_user_avatar" />
				</div>
				<div v-for="item in detaillCon.audit_list" :key="item.id" class="tiemline">
					<img v-if="item.status=='3'" src="../assets/img/icon/icon-10.png">
					<img v-if="item.status=='2'" src="../assets/img/icon/icon-09.png">
					<img v-if="item.status=='1'" src="../assets/img/icon/icon-08.png">
					<van-cell title-class="" :title="item.audit_user_name +' • '+ item.status_str" :value="item.created_at" :icon="item.audit_user_avatar" />
				</div>
			</van-cell-group>
	    </div>
	    
	    <van-tabbar fixed v-if="detaillCon.status=='3'">
			<van-button class="editbtn" icon="edit" @click="editFun" block >重新编辑</van-button>
		</van-tabbar>
	    
	</div>
</template>


<script>
import { Cell, CellGroup ,Tag,Tabbar,Button} from 'vant';	
import {sendPost} from '../assets/js/api.js'

export default {
  name: 'review',
  components: {
  	[Cell.name]: Cell,
  	[CellGroup.name]: CellGroup,
	[Tag.name]: Tag,
	[Tabbar.name]: Tabbar,
	[Button.name]: Button
  },
  data (){
    return {
		myIcon: {
			noAvatar: require('../assets/img/icon/icon-803.png')				
		},		
		detaillCon:{},
		customized:'',
		kickback:'',
		is_price_indexed:'',
		avatar:'',
		pending_audit:''
    }
  },
  created (){
	  sendPost('api/special/sales/apply/detail',{id:this.$route.query.id}).then(res=>{
		let resData = res.data;
		if(resData.code == 0){
			this.detaillCon = resData.data;
			this.customized = (this.detaillCon.customized_card_kind==1) ? '定制' : '不定制';
			this.kickback = (this.detaillCon.is_kickback==1) ? '有佣金' : '无佣金';
			this.is_price_indexed = (this.detaillCon.is_price_indexed==1) ? '符合' : '不符合';
			this.pending_audit=this.detaillCon.pending_audit;
			if(this.detaillCon.sales_way == 1){
				this.detaillCon.sales_way_val=this.detaillCon.sales_way_val+'%';
			}
			if(this.detaillCon.sales_way == 2){
				this.detaillCon.sales_way_val = this.detaillCon.sales_way_val;
			}
			if(this.detaillCon.sales_way == 3){
				this.detaillCon.sales_way_val='无';
			}
		}else{
			this.$toast(res.data.msg);
		}	
	  });
	  this.avatar = localStorage.getItem('avatar') ? localStorage.getItem('avatar') : this.myIcon.noAvatar;
	  
  },
  methods: {
  	  editFun(){
		  this.$router.push({path:'/form',query:{id:this.$route.query.id,title:'修改审批单'}});
	  },
	getQueryString(name){
			var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
			var r = window.location.search.substr(1).match(reg);
			if(r!=null)return  unescape(r[2]); return null;
	} 
  }
}	
</script>

<style scoped>
.review_top .review__txt .tit{ flex: auto; width: 60px !important; color: #999;}
.review_top .review__txt .van-cell__value{flex:1 auto; width: 75% !important; text-align: left;color: #333;}
@media only screen and (width: 375px) and (min-height: 690px){
  .review_bottom{
    padding-bottom: 34px;
  }
 }
</style>





