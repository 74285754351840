<template>
  <div class="oa-cardReceiptDetail">
    <!-- 基本信息 -->
    <div class="basic-info">
      <van-cell-group class="cell-group-1" :border="false">
        <van-row>
          <van-cell class="bigCell" title="基本信息" size="large">
            <van-button plain type="primary" size="mini" color="#307DCD" v-show="contractdetail.status==1">{{contractdetail.statusDesc}}</van-button>
            <van-button plain type="primary" size="mini" color="#4EB240" v-show="contractdetail.status==5">{{contractdetail.statusDesc}}</van-button>
          </van-cell>
        </van-row>
        <van-row>
          <van-col span="12">
            <van-cell title="合同编号" :border="false" :label="contractdetail.id" />
          </van-col>
          <van-col span="12">
            <van-cell title="销售人员" :border="false" :label="contractdetail.sales_name" />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <van-cell title="客户名称" :border="false" :label="contractdetail.enterprise_name" />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <van-cell title="合同总金额" :border="false" :label="contractdetail.total_amount" />
          </van-col>
          <van-col span="12">
            <van-cell :title="contractdetail.cart_type==1 ? '总点数' : '总次数' " :border="false" :label="contractdetail.total_point_times" />
          </van-col> 
        </van-row>
        <van-row>
          <van-col span="12">
            <van-cell title="是否收到全款" :label="contractdetail.situation" />
          </van-col>
          <van-col span="12">
            <van-cell title="是否收到合同" :label="contractdetail.received" />
          </van-col>
        </van-row>
      </van-cell-group>
      <div class="cell-group-selfline">
        <div class="line"></div>
      </div>
      <van-cell-group class="cell-group-2" :border="false">
        <van-row>
          <van-col span="24">
            <van-cell title="卡种名称" :border="false" :label="contractdetail.cardKindName" />
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <van-cell title="使用类型" :label="contractdetail.cardTypeName" />
          </van-col>
          <van-col span="12">
            <van-cell title="有效期" :label="contractdetail.expired_date" />
          </van-col>
        </van-row>
      </van-cell-group>
    </div>
    <!-- 产品信息 -->
    <div class="goods-info">
      <h4>产品信息</h4>
      <ul class="goods-list">
        <li v-for="(item, index) in contractdetail.cardProduct" :key="index">
          <van-row>
            <van-col span="10">
              <div class="goosImgBox" @click="goodsImgShow(item.card_face_image)">
                <img class="showBigImg" src="../assets/makeBigIcon.png" alt="">
                <van-image
                  width="132"
                  height="68"
                  fit="cover"
                  :src="item.card_face_image"
                />
              </div>
            </van-col>
            <van-col span="14">
              <van-cell-group :border="false">
                <van-cell :title="contractdetail.cart_type==1 ? '卡内金额' : '卡内次数'" :border="false" :value="item.card_amount" />
                <van-cell title="卡数量" :border="false" :value="item.card_num" />
                <van-cell title="卡段" :border="false" :value="item.card_string" />
              </van-cell-group>
            </van-col>
          </van-row>
        </li>
      </ul>
    </div>
    <!-- 客户信息 -->
    <div class="kehu-info">
      <h4>客户信息</h4>
      <van-cell-group :border="false">
        <van-cell title="联系人"  :value="contractdetail.enterprise_info" :border="false" />
      </van-cell-group>
    </div>
    <!-- 客户凭证信息 -->
    <div class="pingzheng-info">
      <h4>客户凭证信息 <span>(最多10张)</span></h4>
      <van-uploader 
        class="upImg" 
        :preview-size="previewSize"
        :accept="'image/*'"
        :before-read="beforeRead"
        :after-read="afterRead"
        @delete="deleteImg"
        v-model="fileList" 
        multiple
        :upload-text="fileList.length+'/10'"
        :max-size="3000000"
        :max-count="maxConut" >
        </van-uploader>
    </div>
    <!-- 卡签收日志记录 -->
    <div class="log-info">
      <h4>卡签收日志记录</h4>
      <van-steps direction="vertical" active-color="">
        <van-step v-for="(item, index) in logArrShowList" :key="index">
          <p class="p1">
            <span>{{item.operator}}</span>
            <span class="span2">{{item.created_at}}</span>
          </p>
          <p class="p2">{{item.content}}</p>
        </van-step>
      </van-steps>
      <div class="logmore" v-if="logArr.length>3" @click="logMoreShowHide">
        <div class="logmoreIn" v-show="!isLogShowMore">
          <span>展开更多</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="logmoreIn" v-show="isLogShowMore">
          <span>点击收起</span>
          <van-icon name="arrow-up" />
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div :class="{
      'footer': true,
      'footerIos':isIphone,
      'footerIosX':isIphoneX }">
      <!-- 销售: “待签收”状态 -->
      <div class="xiaoshou" >
        <van-button type="primary" color="#307DCD" @click="onceConfirmfile" v-show="contractdetail.status==1">确认提交</van-button>
        <van-button type="primary" color="#307DCD" @click="twiceConfirmfile" v-show="contractdetail.status==5 && getImgLen<10">确认重新提交</van-button>
      </div>
    </div>

    <!-- 一次上传 modal -->
    <van-dialog 
      class="isOnceShangChuanModal confirmModal" 
      v-model="isOnceShangChuan" 
      :beforeClose="onceShangChuanBeforeClose"
      confirm-button-color="#307DCD" 
      show-cancel-button 
      width="80%">
      <div class="inner">
        <img src="../assets/passIcon.png">
        <p>你上传的内容确认要提交吗？提交后不可撤回哦</p>
      </div>
    </van-dialog>
     <!-- 二次上传 modal -->
    <van-dialog 
      class="isTwiceShangChuanModal confirmModal" 
      v-model="isTwiceShangChuan" 
      :beforeClose="twiceShangChuanBeforeClose"
      confirm-button-color="#307DCD" 
      show-cancel-button 
      width="80%">
      <div class="inner">
        <img src="../assets/passIcon.png">
        <p>你上传的内容确认要提交吗？提交后不可撤回哦</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {Cell, CellGroup, Button, Col, Row, Uploader, Step, Steps, Icon, ImagePreview, Dialog, Field, Toast, Notify, Image} from "vant";
import { sendPost, sendPostFile } from '../assets/js/api.js'
import { platformName } from '../router'
export default {
  name: 'cardReceiptDetail',
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [Uploader.name]: Uploader,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Icon.name]: Icon,
    [ImagePreview.name]: ImagePreview,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Notify.name]: Notify,
    [Image.name]: Image
  },
  data() {
    return {
      contractdetail: {},
      isHaveDelBtn: true, // 是否展示删除按钮
      fileList: [],
      isShowImgPreview: false,
      logArr: [],
      logArrShowList: [],
      isLogShowMore: false,
      isTongGuo: false,
      isBuTongGuo: false,
      isOnceShangChuan: false,
      isTwiceShangChuan: false,
      cid: 0, //合同id
      isAgain: 0,  //第一次上传
      maxConut: 10,
      previewSize: 80,
      getImgLen: 0,
      isIphone: false,
      isIphoneX: false
    }
  },
  created(){
    this.isIphone = this.isIphoneFn();
    this.isIphoneX = this.isIphoneXFn();
    if(this.isIphoneX){
      this.isIphone = false
    }
    // 上传图片控件的宽度
    this.setUpLoadBtn();
    // 获取合同id
    this.cid = this.getQueryString("cid");
    // 获取详情信息
    this.getContractdetail();
    // 获取日志列表
    this.getLogList();
  },
  methods: {
    isIphoneFn(){
      return /iphone/gi.test(navigator.userAgent)
    },
    isIphoneXFn(){
      return /iphone/gi.test(navigator.userAgent) && (screen.height == 812 && screen.width == 375);
    },
    // 上传图片控件的宽度
    setUpLoadBtn(){
      let width = document.body.clientWidth;
      this.previewSize = width<375 ? 78 : 80;
    },   
    // 截取url字段
    getQueryString(name){
      var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if(r!=null)return  unescape(r[2]); return null;
    },
    // 获取详情信息
    getContractdetail() {
      sendPost('api/contract/cardsign/contractdetail', {
        id: this.cid
      }).then(res=>{
        let resData = res.data;
        if(resData.code == 0){
          this.contractdetail = resData.data.item;
          // // 合同金额
          this.contractdetail.total_amount = '¥' + this.contractdetail.total_amount;
          // 总点数
          this.contractdetail.total_point_times = this.contractdetail.total_point_times;
          // isAgain为1  (已签收)
          this.isAgain = this.contractdetail.status == 5 ? 1 : 0;
          // 是否展示删除按钮
          // if(this.contractdetail.status == 1){
          //   // this.isHaveDelBtn = true;
          //   this.maxConut = 10;
          // }else{
          //   // this.isHaveDelBtn = false;
          //   this.maxConut = this.contractdetail.file_url.length;   //主要是显示作用，相当于隐藏按钮
          // }
          //
          //接口拿到上传图片的数量，超过10，隐藏提交按钮
          if(this.contractdetail.file_url) {
            this.getImgLen = this.contractdetail.file_url.length ? this.contractdetail.file_url.length : 0;
          }
          if(resData.data.item.file_url){
            let arr = [];
            resData.data.item.file_url.forEach(function(item){
              arr.push({
                url: item.file_url
              })
            })
            this.fileList = arr;
          }
          // 上一次上传的图片不允许删除，隐藏删除icon
          setTimeout(() => {
            var iDomArr = document.getElementsByClassName('van-icon-clear');
            for (var i = 0; i < this.fileList.length; i++) {
              if(i < this.getImgLen) {
                iDomArr[i].style.display = 'none';
              }
            }
          })
        }
      })
    },
    // 产品信息图片
    goodsImgShow(url) {
      ImagePreview({
        images: [
          url
        ],
      });
    },
    //上传图片之前
    beforeRead(file) {
      if (file.size>3000000) {
        Toast.fail('上传文件不能超过3M');
        return false;
      }
      return true;
    },
    //上传图片之后
    afterRead(file) {
      const isDingTalk = platformName === 'dingtalk'
      let that = this;
      let deviceFile = []  //选择的图片数组
      let formData = new FormData();  //创建form对象
      
      // 删除含有base64的元素
      // that.fileList.forEach(function(item){
      //   if(item.url){
      //     arr1.push(item)
      //   }
      // });
      // that.fileList = arr1;

      
      if(Array.isArray(file)){ //因为该组件单选是对象，多选是数组
        file.status = 'uploading';
        file.message = '上传中...';
        deviceFile = file;
      }else{
        deviceFile.push(file)
      }
      deviceFile.map((item, index)=>{
        item.status = 'uploading';
        item.message = '上传中...';
        formData.append("files["+index+"]", item.file ,item.file.name);
      })
      formData.append("card_sign_id", that.contractdetail.card_sign_id);
      formData.append("isAgain", that.isAgain);
      const request = async function (formData){
        if (isDingTalk) {
          return fetch(process.env.VUE_APP_BASE_URL+ 'api/contract/cardsign/uploadfile', {
            method: 'POST',
            body: formData,
            withCredentials: true,
            headers: {
              token: localStorage.getItem('token')
            }
          }).then(res => {
            return res.json()
          }).catch(err => {
            console.log('fetch err', err)
          })
        }else {
          return sendPostFile('api/contract/cardsign/uploadfile', formData)
        }
      }
      request(formData).then(function(res){
        let resData = isDingTalk ? res : res.data
        console.log(resData)
        let arr2 = resData.data.item;
        let arr1 = [];
        
        that.fileList.forEach(function(item){
          if(item.url){
            arr1.push(item)
          }
        });
        that.fileList = arr1;
        if(resData.code == 0){
          // 当前上传的图片
          arr2.forEach(function(item){
            that.fileList.push({
              "url": item.file_url
            });
          });
        }
      })
    },
    // 删除图片
    deleteImg(parm) {
      let that = this;
      let arr = [];
      that.fileList.forEach(function(item){
        if(item.url != parm.url){
          arr.push(item)
        }
      });
      that.fileList = arr;
    },
    // 点击“确认提交”
    onceConfirmfile() {
      if(!this.fileList.length){
        this.$notify("请上传客户凭证");
        return false;
      }
      this.isOnceShangChuan = true;
    },
    // 一次上传modal
    onceShangChuanBeforeClose(action, done) { 
      if (action === 'cancel') {  //取消按钮
        done();
      }
      if (action === 'confirm') { //确定按钮
      
        sendPost('api/contract/cardsign/confirmupload',{
          card_sign_id: this.contractdetail.card_sign_id,
          imgurl_array: this.fileList,
          isAgain: this.isAgain
        }).then(res=>{
          let resData = res.data;
          if(resData.code == 0){
            this.getContractdetail();
            this.getLogList();
            done();
          }else{
            this.$notify(resData.msg);
            done();
          }
        })
      }
    },
    // 点击“确认重新提交”
    twiceConfirmfile() {
      if(!this.fileList.length){
        this.$notify("请上传客户凭证");
        return false;
      }
      this.isTwiceShangChuan = true;
    },
    // 二次上传modal （此处本来可以和一次上传公用代码，但是产品想法阴晴不定，经常改动，所以分开写，应对产品需求变幻莫测）
    twiceShangChuanBeforeClose(action, done) { 
      if (action === 'cancel') {  //取消按钮
        done();
      }
      if (action === 'confirm') { //确定按钮
        sendPost('api/contract/cardsign/confirmupload',{
          card_sign_id: this.contractdetail.card_sign_id,
          imgurl_array: this.fileList,
          isAgain: this.isAgain
        }).then(res=>{
          let resData = res.data;
          if(resData.code == 0){
            this.getContractdetail();
            this.getLogList();
            done();
          }else{
            this.$notify(resData.msg);
            done();
          }
        })
      }
    },
    // 获取日志列表
    getLogList() {
      sendPost('api/contract/cardsign/logs',{
        contract_id: this.cid
      }).then(res=>{
        let resData = res.data;
        if(resData.code == 0){
          this.logArr = resData.data.item;
          if(this.logArr.length<=3) {
            this.logArrShowList = this.logArr;
          }else{
            this.logArrShowList = this.logArr.slice(0,3);
          }
        }
      })
    },
    // 日志点击展开/收起
    logMoreShowHide() {
      if(this.logArrShowList.length<=3) {
        this.logArrShowList = this.logArr;
        this.isLogShowMore = true;
      }else{
        this.logArrShowList = this.logArr.slice(0,3);
        this.isLogShowMore = false;
      }
    },
    // 点击审核通过
    clickTongGuoBtn() {
      this.isTongGuo = true;
    },
    // 审核通过modal
    tongGuoBeforeClose(action, done) {
      if (action === 'cancel') {  //取消按钮
        done();
      }
      if (action === 'confirm') { //确定按钮
        sendPost('api/contract/cardsign/pass',{
          card_sign_id: this.contractdetail.card_sign_id,
        }).then(res=>{
          let resData = res.data;
          if(resData.code == 0){
            this.getContractdetail();
            this.getLogList();
            done();
          }else{
            this.$notify(resData.msg);
            done();
          }
        })
      }
    }
  }
}
</script>
<style scoped>
  .oa-cardReceiptDetail{
    background: #F5F6F9;
  }
  .van-button{
    width: auto;
    padding: 0 7px;
    line-height: 22px;
  }
  .van-cell{
    padding: 5px 16px;
  }
  .van-cell__title{
    color: #999;
    font-size: 12px;
  }
  .van-cell__label{
    color: #333;
    font-size: 14px;
  }
  .van-cell__value{
    color: #333;
    font-size: 14px;
  }
  .van-step--vertical{
    padding: 0;
  }
  .warn-info{
    padding: 10px 15px;
    background: #FFEDED;
    position: relative;
    font-weight: bold;
  }
  .warn-info .warnIcon{
    position: absolute;
    left: 15px;
    top: 12px;
    font-size: 14px;
    color: #FF4949;
    font-weight: bold;
  }
  .warn-info p{
    font-size: 12px;
    line-height: 17px;
    color: #FF4949;
    margin: 0;
    padding: 0;
    padding-left: 20px;
  }
  .bigCell{
    padding: 15px 16px 10px;
  }
  .bigCell .van-cell__title{
    color: #333;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
  .cell-group-1{
    padding-bottom: 5px;
  }
  .cell-group-selfline{
    padding: 0 15px;
    background: #fff;
  }
  .cell-group-selfline .line{
    background: #ebedf0;
    height: 1px;
  }
  .cell-group-2{
    padding-bottom: 10px;
  }
  .goods-info{
    padding: 15px;
    margin-top: 10px;
    background: #fff;
  }
  .goods-info h4{
    font-size: 16px;
    color: #333;
    line-height: 22px;
    padding: 0;
    margin: 0;
  }
  .goods-list li{
    margin-top: 10px;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid #F0F0F0;
    box-shadow: 1px 4px 8px #F0F0F0;
  }
  .goods-list li .goosImgBox{
    width: 132px;
    height: 68px;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
  } 
  .goods-list li .goosImgBox .showBigImg{
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .goods-list li .van-cell{
    padding: 0 0 0 10px;
  }
  .goods-list li .van-cell .van-cell__title{
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    font-size: 12px;
    color: #999;
  }
  .goods-list li .van-cell .van-cell__value{
    text-align: left;
    -webkit-box-flex: 2.2;
    -webkit-flex: 2.2;
    flex: 2.2;
    font-size: 14px;
    color: #333;
  }
  .kehu-info{
    padding: 15px;
    background: #fff;
    margin-top: 10px;
  }
  .kehu-info h4{
    font-size: 16px;
    color: #333;
    line-height: 22px;
    padding: 0;
    margin: 0;
  }
  .kehu-info .van-cell{
    padding: 10px 15px  5px 1px;
  }
  .kehu-info .van-cell .van-cell__title{
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    font-size: 12px;
  }
  .kehu-info .van-cell .van-cell__value{
    -webkit-box-flex: 5;
    -webkit-flex: 5;
    flex: 5;
    text-align: left;
    font-size: 14px;
  }
  .pingzheng-info{
    padding: 15px 0 15px 15px;
    background: #fff;
    margin-top: 10px;
  }
  .pingzheng-info h4{
    font-size: 16px;
    color: #333;
    line-height: 22px;
    padding: 0;
    margin: 0;
  }
  .pingzheng-info h4 span{
    font-size: 12px;
    color: #999;
    margin-left: 10px;
    font-weight: normal;
  }
  .pingzheng-info .imgList li{
    width: 78px;
    height: 78px;
    border-radius: 2px;
    border: 1px dashed #ddd;
    text-align: center;
  }
  .pingzheng-info .imgList li span{
    font-size: 12px;
  }
  .upImg{
    padding-top: 15px;
    padding-bottom: 12px;
  }
  .log-info{
    padding: 15px 15px 10px;
    background: #fff;
    margin-top: 10px;
  }
  .log-info h4{
    font-size: 16px;
    color: #333;
    line-height: 22px;
    padding: 0;
    margin: 0;
  }
  .log-info .van-step--vertical{
    padding-top: 10px;
  }
  .log-info .van-step--vertical:not(:last-child)::after {
    border-bottom-width: 0;
  }
  .log-info p{
    padding: 0;
    margin: 0;
  }
  .log-info p.p2{
    padding-top: 6px;
    font-size: 14px;
    line-height: 20px;
    color: #333;
  }
  .log-info p span{
    display: inline-block;
    width: 50%;
    font-size: 12px;
    color: #666;
  }
  .log-info p span.span2{
    text-align: right;
    color: #999;
  }
  .log-info .van-step__circle {
    color: #E5E5E5;
    width: 10px;
    height: 10px;
  }
  .log-info .logmore{
    text-align: center;
    margin-top: 5px;
  }
  .log-info .logmoreIn{
    height: 40px;
    line-height: 42px;
  }
  .log-info .logmore span{
    color: #999;
    font-size: 13px;
    line-height: 16px;
    line-height: 40px;
    margin-right: 5px;
  }
  .log-info .logmore i{
    color: #999;
    font-size: 13px;
    font-size: 14px;
   }
   /* 安卓 */
  .footer{
    padding: 7px 15px 8px;
    margin-top: 10px;
    background: #fff;
    text-align: center;
  }
  /* iphone */
  .footerIos{
    padding: 0 15px;
    margin-top: 10px;
    background: #fff;
    text-align: center;
  }
  /* iphoneX */
  .footerIosX{
    padding: 0 15px 15px;
    margin-top: 10px;
    background: #fff;
    text-align: center;
  }
  .footer .van-button, .footerIos .van-button, .footerIosX .van-button{
    width: 100%;
    font-size: 16px;
  }
  .confirmModal .inner{
    text-align: center;
    padding: 20px 20px 0;
  }
  .confirmModal .inner img{
    width: 95px;
    height: 72px;
  }
  .confirmModal .inner img{
    width: 95px;
    height: 72px;
  }
</style>