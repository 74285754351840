<template>	
	<div class="review">
		<div class="review_top">
			<div class="title_top">
			<van-cell size="large" center title="我的体验卡" icon="@/assets/img/icon/icon-01.png" />
			<van-tag class="review_status" plain color="#F44545">已拒绝</van-tag>
			</div>
			<div class="review__txt">
				<van-cell title-class="tit" title="拒绝原因" value="这是拒绝原因这是拒绝原因哦这是拒绝原因这是拒绝原因哦" />
			</div>
		</div>
		
		<div class="review_top">
			<div class="title_top">
			<van-cell size="large" center title="我的体验卡" icon="@/assets/img/icon/icon-01.png" />
			<van-tag class="review_status" plain color="#4EB240">已通过</van-tag>
			</div>
			
		</div>

		<van-cell-group title="申请人信息" class="group_bd">
		  <van-cell title-class="tit" title="发起人" value="木可可" />
		  <van-cell title-class="tit" title="城市" value="南京分公司" />
		  <van-cell title-class="tit" title="所属部门" value="运营部" />
		</van-cell-group>		

		
		<van-cell-group title="客户信息" class="group_bd">
		  <van-cell title-class="tit" title="客户名称" value="成都第三建筑集团" />
		  <van-cell title-class="tit" title="申请理由" value="维护客情" />
		</van-cell-group>
		
		<van-cell-group title="产品信息" class="group_bd">
		  <van-cell title-class="tit" title="使用类型" value="点卡" />
		  <van-cell title-class="tit" title="选择卡种" value="成都-东福超级卡（券）" />
		  <van-cell title-class="tit" title="单张卡内金额" value="¥100.00" />
		  <van-cell title-class="tit" title="卡数量" value="2" />
		  <van-cell class="total" title-class="tit" title="核算金额" value-class="" value="¥200.00" />
		</van-cell-group>	

	    <div class="total_h3">近30天体验卡金额情况</div>
			<van-cell title="木可可共申请通过" is-link value="¥580.00" url="/reviewRejectCard" />
			<van-cell title="您共审批通过" is-link value="¥5800.00" to="/reviewRejectCard" />			
			    
	    <div class="review_bottom">
	    	<van-cell-group title="审批流程" size="large" class="">
			  <img style="width: 20px; display: inline-block;" src="../assets/img/icon/icon-10.png">
			  <van-cell title-class="" title="李晓雅 . 已拒绝" value="04-08 09" icon="../assets/img/icon/icon-08.png" />
			  </van-cell-group>
	    </div>   
	    
		<div class="bottom_btn">
			<van-button plain hairline type="info">拒绝</van-button>
			<van-button class="right_btn" type="info">通过</van-button>
		</div>
	    
	</div>
</template>


<script>
import { Cell, CellGroup } from 'vant';
import { Tag } from 'vant';	
import { Button } from 'vant';
import {sendPost} from '../assets/js/api.js'
export default {
  name: 'reviewRejectCard',
  components: {
  	[Cell.name]: Cell,
  	[CellGroup.name]: CellGroup,
	[Tag.name]: Tag,
	[Button.name]: Button
	  
  
  },
data (){
    return {
		msgDetail:[]
    }
  },
  methods:{
	  	
		formatNumber:function(n) {
			n = n.toString()
			return n[1] ? n : '0' + n
		},
		// 时间格式化
		formatTime:function(date,c) {
			let year = date.getFullYear()
			let month = date.getMonth() + 1

				if(month<=c){
					year = year -1;
					month = month - c + 12
				}else{
					month = month - c
				}			
			return [year, month].map(this.formatNumber).join('-')
		}
  },
  created (){
	  console.log("99");

	let  _nowDate = new Date();
		let _timeTxt = "";
		if(_nowDate.getMonth()+1<10){
			_timeTxt = _nowDate.getFullYear() + "-0" + (_nowDate.getMonth()+1);
		}else{
			_timeTxt = _nowDate.getFullYear() + "-" + (_nowDate.getMonth()+1);
		}
		//this.monthRecord.month.push(_timeTxt);

			for(var i=0;i<6;i++){
				//console.log(i);
				if(_nowDate.getMonth()+1-i>0){
					console.log(_nowDate.getMonth()+1-i+":::i="+i);
				}else{
					console.log(_nowDate.getMonth()+1-i+12+":::i="+i);
				}

			}


		
			// for(var i = _nowDate.getMonth()+1;i>_nowDate.getMonth()+1-6;i--){
			// 	if(_nowDate.getMonth()+1-6<=0){
			// 		console.log(i+12);
			// 	}else{
			// 		console.log(i);
			// }
			
			//this.formatTime(new Date(),i);
			//console.log(this.formatTime(new Date(),i));
			//_nowDate.getMonth()+1-6

			//}


	 
			





	
  }
}
</script>
<style scoped="">
.bottom_btn{position: fixed; bottom: 0; left: 0; z-index: 9999; width: 100%; padding:10px 0; background: #fff;box-shadow:0px 0px 8px 0px rgba(0,0,0,0.1);}
.bottom_btn .van-button{ width: 40%; margin-left: 15px;}
.bottom_btn .van-button.right_btn{ float:right; margin-right: 15px;}

.group_bd .van-cell{ padding: 5px 15px;}
.review_top {border-bottom: 1px solid #ebedf0;margin-bottom: 10px;}
.review_top .title_top{ padding-right: 60px; position: relative; }
.review_top .review_status{ position: absolute; top: 13px; right: 15px;}
.review_top .van-cell:not(:last-child)::after{ border-bottom: 0;}
.review_top .review__txt .tit{ flex: auto; width: 60px !important; color: #999;}
.review_top .review__txt .van-cell__value{flex: auto; width: 75% !important; text-align: left;}

.group_bd{ border-bottom: 15px solid #f5f6f9}
.group_bd .van-hairline--top-bottom::after{ border-width:0 0;}
.group_bd .van-cell:not(:last-child)::after{border-bottom:0;}
.group_bd.van-hairline--top-bottom::after{border-width:0 0 !important;}

.group_bd .van-cell__title, 
.group_bd .van-cell__value{ flex:auto !important;}
.group_bd .van-cell__title{ width:60px !important; color: #999;}
.group_bd .van-cell__value{ width: 75% !important; padding-left: 10px; text-align: left; color: #333;}

.van-cell-group__title{ color: #333; font-weight: bold;margin-bottom: 5px;}

.group_bd .van-cell.total{ border-top: 0.5Px solid #efefef; margin-top: 5px; padding: 11px 15px;}
.group_bd .van-cell.total .van-cell__value{ color: #F44545;}

.review_bottom{ }
.review_bottom .van-cell-group__title{color: #333;}
.total_h3{ background:#F5F6F9; padding:5px 15px 12px 15px; color:#999; font-size:12px;}

</style>




