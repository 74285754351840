<template>	
	<div class="review" :class="{'revbot':detaillCon.status=='1'}">
		<div class="review_top card_top">
			<van-cell size="large" center :title="detaillCon.applicant_name?detaillCon.applicant_name+'的特批审批单':'的特批审批单'" :icon="avatar" />
			<van-tag class="review_status" v-if="detaillCon.status==2" plain color="#4EB240">已通过</van-tag>
			<van-tag class="review_status" v-if="detaillCon.status==3" plain color="#F44545">已拒绝</van-tag>
			<div class="review__txt"  v-if="detaillCon.status==3">
				<van-cell title-class="tit" title="拒绝原因" :value="detaillCon.reject_reason" />
			</div>
		</div>		
		<van-cell-group title="" class="group_bd">
			<van-cell title-class="tit" title="发起人" :value="detaillCon.applicant_name" />
			<van-cell title-class="tit" title="城市" :value="detaillCon.city_name" />
			<van-cell title-class="tit" title="所属部门" :value="detaillCon.department_name" />
			<van-cell title-class="tit" title="总经理" :value="detaillCon.manager_name" />
		</van-cell-group>
		<van-cell-group title="" class="group_bd">
			<van-cell title-class="tit" title="客户名称" :value="detaillCon.enterprise_name" />
			<van-cell title-class="tit" title="预算类型" :value="detaillCon.budget_type_name" />
			<van-cell title="卡种是否定制" :value="customized" />
			<van-cell title="申请产品" v-if="detaillCon.customized_card_kind==1" :value="detaillCon.apply_product" />
			<van-cell title="选择卡种" v-if="detaillCon.customized_card_kind==2" :value="detaillCon.common_kind_card_name" />
			<van-cell title="单价" :value="'¥'+detaillCon?detaillCon.unit_price:''" />
			<van-cell title="数量" :value="detaillCon.num" />
			<van-cell title="采购预算" :value="'¥'+detaillCon?detaillCon.purchase_budget:''" />
			<van-cell title="配赠比例 /折扣" :value="detaillCon.sales_way_val" />
		</van-cell-group>
		<van-cell-group title="" class="group_bd">
			<van-cell title-class="tit" title="溢价佣金" v-if="detaillCon.is_kickback==1" :value="'¥'+detaillCon.kickback" />
			<van-cell title-class="tit" title="除去溢价佣金后单价" v-if="detaillCon.is_kickback==1" :value="'¥'+detaillCon.kickback_unit_price" />
			<van-cell title-class="tit" title="是否符合量价挂钩" :value="is_price_indexed" />
			<van-cell title-class="tit" title="特批内容" :value="detaillCon.rule_content" />
			<van-cell title-class="tit" title="审批原因" :value="detaillCon.approval_reason" />
		</van-cell-group>
		  
	    <div class="review_bottom" v-if="detaillCon.status!='1'">
	    	<van-cell-group title="审批流程" size="large">
				<div v-for="item in detaillCon.audit_list" :key="item.id" class="tiemline">
					<img v-if="item.status=='3'" src="../assets/img/icon/icon-10.png">
					<img v-if="item.status=='2'" src="../assets/img/icon/icon-09.png">
					<van-cell title-class="" :title="item.audit_user_name +' • '+ item.status_str" :value="item.created_at" :icon="item.audit_user_avatar" />
				</div>
			</van-cell-group>
	    </div>
	    <van-tabbar fixed v-if="statusShow">
			<van-button type="default" @click="auditReject">拒绝</van-button>
			<van-button type="info" @click="viaFun">通过</van-button>
		</van-tabbar>
	    
	</div>
</template>


<script>
import { Cell, CellGroup ,Tag,Tabbar,Button,Dialog } from 'vant';	
import {sendPost} from '../assets/js/api.js'

export default {
  name: 'review',
  components: {
  	[Cell.name]: Cell,
  	[CellGroup.name]: CellGroup,
	[Tag.name]: Tag,
	[Tabbar.name]: Tabbar,
	[Dialog.name]: Dialog,
	[Button.name]: Button
  },
  data (){
    return {
		myIcon: {
			noAvatar: require('../assets/img/icon/icon-803.png')				
		},
		isLoading:false,
		detaillCon:{},
		customized:'',
		kickback:'',
		is_price_indexed:'',
		avatar:''
    }
  },
  computed:{
	  statusShow:function(){
		  return this.detaillCon.status=='1' && (this.detaillCon.audit_id == localStorage.getItem('user_id') || this.detaillCon.major_account_id == localStorage.getItem('user_id'))
	  }
  },
  created (){
	  sendPost('api/special/sales/apply/detail',{id:this.$route.query.id}).then(res=>{
		let resData = res.data;
		if(resData.code == 0){
			this.detaillCon = resData.data;
			this.customized = (this.detaillCon.customized_card_kind==1) ? '定制' : '不定制';
			this.kickback = (this.detaillCon.is_kickback==1) ? '有佣金' : '无佣金';
			this.is_price_indexed = (this.detaillCon.is_price_indexed==1) ? '符合' : '不符合';
			this.avatar = this.detaillCon.applicant_avatar ? this.detaillCon.applicant_avatar : this.myIcon.noAvatar;
			if(this.detaillCon.sales_way == 1){
				this.detaillCon.sales_way_val=this.detaillCon.sales_way_val+'%';
			}
			if(this.detaillCon.sales_way == 2){
				this.detaillCon.sales_way_val = this.detaillCon.sales_way_val;
			}
			if(this.detaillCon.sales_way == 3){
				this.detaillCon.sales_way_val='无';
			}
		}
	  });
	  
  },
  methods: {
	  auditReject(){
			this.$router.push({path:'/refuse',query:{id:this.$route.query.id,type:1}});	
	  },
	  viaFun(){
		  	let _this = this;
				function beforeClose(action, done) {
					console.log(_this.$route.query.id);
					if (action === 'confirm') {	
						_this.isLoading=true;
						sendPost('api/special/sales/apply/audit',{id:_this.$route.query.id,status:'2',reject_reason:''}).then(res=>{
							_this.isLoading=false;
							let resData = res.data;
							done();
							if(resData.code == 0){
								_this.$router.push('/listTabA');
							}
							if(resData.code == 1010025){
								Toast('操作失败！');
							}
							if(resData.code == 1010001){
								Toast('参数错误！');
							}
						});					
					} else {
						done();
					}
				}

				Dialog.confirm({
					message: '你确定是否审核通过？',
					className:'viaalert',
					beforeClose
				});





		// Dialog.confirm({
		// 	message: '你确定是否审核通过？',
		// 	className:'viaalert'
		// }).then(() => {
		// 	this.isLoading=true;
		// 	sendPost('api/special/sales/apply/audit',{id:this.$route.query.id,status:'2',reject_reason:''}).then(res=>{
		// 		this.isLoading=false;
		// 		let resData = res.data;
		// 		if(resData.code == 0){
		// 			this.$router.push('/listTabA');
		// 		}
		// 		if(resData.code == 1010025){
		// 			Toast('操作失败！');
		// 		}
		// 		if(resData.code == 1010001){
		// 			Toast('参数错误！');
		// 		}
		// 	});
		// }).catch(() => {
		// // on cancel
		// });





	  }
  }
}	
</script>
<style>
.group_bd .van-cell{ padding: 5px 15px;}
.review_top{ /*padding-right: 60px;*/ margin-bottom: 10px; position: relative; border-bottom: 1px solid #ebedf0;}
.review_top .review_status{ position: absolute; top: 13px; right: 15px;}
.review_top .van-cell:not(:last-child)::after{ border-bottom: 0;}
.card_top.review_top{padding-right: 0;}
.card_top.review_top .van-cell--large{padding-right: 60px;}
.card_top.review_top .van-cell__title.tit{ width: 60px !important; flex:1 auto; color: #999;}
.card_top.review_top .van-cell__value{flex:1 auto; width: 74% !important; text-align: left; color: #333;}

.group_bd .van-hairline--top-bottom::after{ border-width:0 0;}
.group_bd .van-cell:not(:last-child)::after{border-bottom:0;}
.group_bd.van-hairline--top-bottom::after{border-width:0 0 !important;}
.group_bd{ padding-bottom:15px;}
.group_bd .van-cell__title, 
.group_bd .van-cell__value{ flex:auto !important;}
.group_bd .van-cell__title{ width:60px !important; color: #999;}
.group_bd .van-cell__value{ width: 75% !important; padding-left: 10px; text-align: left; color: #333;}
.review_bottom{ border-top: 10px solid #f5f6f9;}
.review_bottom .van-cell-group__title{color: #333;}
.review .tiemline{padding-left:10px;display:flex;align-items: center;}
.review .tiemline > img{width:20px;height:20px;}
.review .tiemline .van-cell{padding:10px;}
.review .tiemline .van-cell__left-icon,.review_top .van-cell__left-icon{font-size:30px;height:30px;line-height: 30px;}
.review .tiemline .van-cell span,.review_top .van-cell span{line-height: 30px;}
.review .editbtn{border:0;color:#307DCD}
.revbot{padding-bottom: 60px;}
.review .van-tabbar{height: 55px;}
.review .van-tabbar .van-button{margin:10px;}
.review .van-button{line-height: 40px;height:40px;font-size:15px;vertical-align: top;}
.viaalert .van-dialog__content{padding-top:75px;background:url(../assets/img/icon/icon-07.png) 50% 20px no-repeat;background-size:95px auto;}
</style>




