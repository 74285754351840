import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "amfe-flexible/index.js";

//import test from './test'

//Vue.config.productionTip = false
new Vue({
	//test,
  router,
  store,
  render: h => h(App)
}).$mount('#app')



