<template>
  <div class="oa-cardReceiptList">
    <van-tabs 
      color="#4B76AF"
      title-active-color="#4B76AF"
      line-height="1"
      @click="tabClick"
      >
      <van-tab title="待签收" >
        <van-search
          v-model="keyword1"
          show-action
          placeholder="搜索客户编码、客户名称"
          @search="onSearch(1)"
        >
          <template #action>
            <van-button class="serchbtn" type="info" size="small"  @click="onSearch(1)">查询</van-button>
          </template>
        </van-search>
        <div class="loadingBox" v-if="loading1">
          <van-loading size="24px">加载中...</van-loading>
        </div>
        <noDataPage v-if="isNoData1" text="暂无数据" :url="icon.atMe"></noDataPage>
        <div class="listItem"  @click="toDetail(item.id)" v-for="item in cardReceiptList1" :key="item.id">
          <van-cell-group :border="false">
            <van-cell class="bigCell" :title="'合同编号 '+item.id" size="large">
              <van-button plain type="primary" size="mini" :color="item.color">{{item.status_desc}}</van-button>
            </van-cell>
            <van-cell class="kehu" title="客户名称" :border="false" :value="item.enterprise_name" />
            <van-cell title="合同总金额" :border="false" :value="'¥'+item.total_amount" />
            <van-cell :title="item.cart_type == 1 ? '总点数' : '总次数'" :border="false" :value="item.total_point_times" />
            <van-cell title="销售人员" :border="false" :value="item.sales_name" />
          </van-cell-group>
        </div>
      </van-tab>
      <van-tab title="已签收">
        <van-search
          v-model="keyword2"
          show-action
          placeholder="搜索客户编码、客户名称"
          @search="onSearch(2)"
        >
          <template #action>
            <van-button class="serchbtn" type="info" size="small" @click="onSearch(2)">查询</van-button>
          </template>
        </van-search>
        <div class="loadingBox" v-if="loading2">
          <van-loading size="24px">加载中...</van-loading>
        </div>
        <noDataPage v-if="isNoData2" text="暂无数据" :url="icon.atMe"></noDataPage>
        <div class="listItem"  @click="toDetail(item.id)" v-for="item in cardReceiptList2" :key="item.id">
          <van-cell-group :border="false">
            <van-cell class="bigCell" :title="'合同编号 '+item.id" size="large">
              <van-button plain type="primary" size="mini" :color="item.color">{{item.status_desc}}</van-button>
            </van-cell>
            <van-cell class="kehu" title="客户名称" :border="false" :value="item.enterprise_name" />
            <van-cell title="合同总金额" :border="false" :value="'¥'+item.total_amount" />
            <van-cell :title="item.cart_type == 1 ? '总点数' : '总次数'" :border="false" :value="item.total_point_times" />
            <van-cell title="销售人员" :border="false" :value="item.sales_name" />
          </van-cell-group>
        </div>
      </van-tab>
      <van-tab title="全部">
        <van-search
          v-model="keyword3"
          show-action
          placeholder="搜索客户编码、客户名称"
          @search="onSearch(3)"
        >
          <template #action>
            <van-button class="serchbtn" type="info" size="small" @click="onSearch(3)">查询</van-button>
          </template>
        </van-search>
        <div class="loadingBox" v-if="loading3">
          <van-loading size="24px">加载中...</van-loading>
        </div>
        <noDataPage v-if="isNoData3" text="暂无数据" :url="icon.atMe"></noDataPage>
        <div class="listItem"  @click="toDetail(item.id)" v-for="item in cardReceiptList3" :key="item.id">
          <van-cell-group :border="false">
            <van-cell class="bigCell" :title="'合同编号 '+item.id" size="large">
              <van-button plain type="primary" size="mini" :color="item.color">{{item.status_desc}}</van-button>
            </van-cell>
            <van-cell class="kehu" title="客户名称" :border="false" :value="item.enterprise_name" />
            <van-cell title="合同总金额" :border="false" :value="'¥'+item.total_amount" />
            <van-cell :title="item.cart_type == 1 ? '总点数' : '总次数'" :border="false" :value="item.total_point_times" />
            <van-cell title="销售人员" :border="false" :value="item.sales_name" />
          </van-cell-group>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Button, Loading, search} from "vant";
import { sendPost } from '../assets/js/api.js';
import noDataPage from '@/components/noDataPage.vue'

export default {
  name: 'cardReceiptList',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Loading.name]: Loading,
    [noDataPage.name]: noDataPage,
    [search.name]: search
  },
  data() {
    return {
      icon: {
        atMe: require('../assets/img/icon/icon-804.png'),//抄送 搜索
        mySearch: require('../assets/img/icon/icon-802.png')       
      },
      cardReceiptList1: [], // 待签收
      cardReceiptList2: [], // 已签收
      cardReceiptList3: [], // 全部
      sales: 0,
      company_id: 0,
      isSales: 1,  // 销售
      keyword: '',
      keyword1: '',
      keyword2: '',
      keyword3: '',
      isNoData1:false,
      isNoData2:false,
      isNoData3:false,
      loading1:false,
      loading2:false,
      loading3:false,
      loadingOnce1:false,
      loadingOnce2:false,
      loadingOnce3:false
    }
  },
  created() {
    // 判断是销售 or 销助
    this.checkIsSalesOrAssistant();
    // 默认进来，拿第一个tab数据
    this.getCardReceiptList(1);
  },
  methods: {
    // 判断是销售 or 销助
    checkIsSalesOrAssistant:function(){
      this.isSales = localStorage.getItem('isSales');
    },   
    // tab切换
    tabClick(tab) {
      this.keyword = this['keyword'+ Number(tab+1)];
      this.getCardReceiptList(tab+1);
    },
    onSearch(num) {
      this.keyword = this['keyword'+num];
      this.getCardReceiptList(num);
    },
    //  获取每个tab下面的数据
    getCardReceiptList(tab) {
      // 每个tab加载一次就不再显示loding
      if(!this['loadingOnce' + tab]){
        this['loading'+tab] = true;
      }
      sendPost('api/contract/cardsign/getlist', {
        tab: tab,
        keyword: this.keyword
      }).then(res=>{
        let resData = res.data;
        let attrName = 'cardReceiptList' + tab;
        this['loading'+tab] = false;
        this['loadingOnce'+tab] = true;
        if(resData.code == 0){
          let arr = resData.data.item;
          if(arr.length>0){
            this['isNoData'+tab] = false;
          }else{
            this['isNoData'+tab] = true;
          }
          // 设置状态显示颜色
          arr.forEach(function(item){
            if(item.status==1){ //  待签收
              item.color = '#307DCD';
            }else if (item.status==5){  //  审批通过
              item.color = '#4EB240';
            }
          })
          this[attrName] = arr;
        }
      })
    },
    toDetail(id) {
      this.$router.push({path:'/cardReceiptDetail',query:{cid:id}});
    }
  }
}
</script>
<style scoped>
  /* body{
    background: #F5F6F9;
  } */
  .oa-cardReceiptList{
    background: #F5F6F9;
  }
  .van-search{
    padding: 10px 7px 10px 15px;
  }
  .serchbtn{
    line-height: 28px;
  }
  .loadingBox{
    text-align: center;
    background: #fff;
    padding-top: 30px;
  }
  .no_data_page{
    margin:0;
    padding: 60px 30px;
    background: #fff;
  }
  .listItem{
    margin-top: 10px;
    background: #fff;
    padding-bottom: 8px;
  }
  .listItem .van-cell{
    padding: 8px 16px;
    line-height: 15px;
  }
  .van-cell:not(:last-child)::after{
    left: 0;
    margin: 0 16px;
  }
  .listItem .van-cell__title{
    color: #999;
    font-size: 14px;
  }
  .listItem .van-cell__value{
    color: #333;
  }
  .listItem .bigCell {
    padding: 10px 16px;
    line-height: 24px;
  }
  .listItem .bigCell .van-cell__title{
    color: #666;
    font-size: 13px;
  }
  .listItem .kehu .van-cell__title{
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
  }
  .listItem .kehu {
    margin-top: 8px;
  }
  .listItem .kehu .van-cell__value{
    -webkit-box-flex: 3;
    -webkit-flex: 3;
    flex: 3;
    overflow: hidden;/*超出部分隐藏*/
    white-space: nowrap;/*不换行*/
    text-overflow:ellipsis;/*超出部分文字以...显示*/
  }
  .listItem .van-button{
    width: auto;
    padding: 0 7px;
    line-height: 22px;
  }
</style>