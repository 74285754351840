<template>
  <div class="bottomTabBar">
		<van-tabbar v-model="active" route>
		  <van-tabbar-item to="/approval" v-if="isCheck">
		    <span>新申请</span>
		    <img
		      slot="icon"
		      slot-scope="props"
		      :src="props.active ? icon.active1 : icon.normal1"
		    >
		  </van-tabbar-item>
		  <van-tabbar-item :to="activeNum" v-if="isMy">
		    <span>我审批的</span>
		    <img
		      slot="icon"
		      slot-scope="props"
		      :src="props.active ? icon.active2 : icon.normal2"
		    >
		  </van-tabbar-item>
		  <van-tabbar-item to="/mySubmit">
		    <span>已提交的</span>
		    <img
		      slot="icon"
		      slot-scope="props"
		      :src="props.active ? icon.active3 : icon.normal3"
		    >
		  </van-tabbar-item>
		</van-tabbar>
  </div>
</template>

<script>
import { Row, Col , Tabbar ,TabbarItem} from 'vant';

export default {
  name: 'home',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  props:{
	  activeNum:String
	 
  },
  data() {
    return {
      active: 0,
      icon: {
        normal1: require('../assets/img/icon/icon-14.png'),
        active1: require('../assets/img/icon/icon-15.png'),
        normal2: require('../assets/img/icon/icon-12.png'),
        active2: require('../assets/img/icon/icon-13.png'),
        normal3: require('../assets/img/icon/icon-16.png'),
        active3: require('../assets/img/icon/icon-17.png')
	  },
	  isCheck:localStorage.getItem('slug').split(',').includes('h5_approval_list'),
	  isMy:localStorage.getItem('slug').split(',').includes('h5_approval_list_my')
    }
  }
}
</script>

<style>
.bottomTabBar{
	height: 55px;
}

/* .bottomTabBar .van-col img{
	width:32px;
	height:32px;
	margin-bottom:5px;
} */
.bottomTabBar .van-col h4{
	margin:0;
	font-weight:normal;
	font-size:12px;
	color:#f00;
}
.bottomTabBar .van-col p{
	margin:0;
	font-size:10px;
	color:#f00;
}
.bottomTabBar .van-tabbar-item{ color: #999;}
.bottomTabBar .van-tabbar-item--active{ color: #307DCD;}

.bottomTabBar .van-tabbar-item__icon{ margin-bottom: 0 !important;}
.bottomTabBar .van-tabbar-item__icon img{
	width:32px;
	height:32px;
	
}
.bottomTabBar .van-tabbar{
	height:55px;
}

</style>


















