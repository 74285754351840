<template>	
	<div class="listTab">
		<div class="search_box">
			<form action="/">
			<van-search 
				placeholder="搜索人名、标题、内容、状态、业务编码" 				
				v-model="keyword"
				background="#F5F6F9"
				 @search="onSearch"	
				 show-action				 			 				 
				 @clear="clearKeyWord"				 
				>
					<div slot="action" @click="onSearch">搜索</div>
				</van-search>
			</form>
		</div>

		

		<van-tabs v-model="active" @click="onChange" @disabled="onClickDisabled">
		  <van-tab title="待处理">
			  <van-list 
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoadPull"
				>
			  	<!-- <noDataPage v-if="isNoData" text="抱歉，暂无搜索结果～" icon="search"></noDataPage> -->
				<noDataPage v-if="isNoData" :text="noDataTxt" :url="noDataImg"></noDataPage>
				<div v-for="(list,id) in listTab" :key="id">								
					<div class="item_bd" @click="listToLink(list.type,list.id)" v-if="list.status==1">
						<div class="h3_tit">
							{{list.title}}
							<!-- <van-tag class="h3_tag" plain type="success">已通过</van-tag> -->
						</div>
						<div class="des_p">申请理由：{{list.apply_reason}}</div>
						<div class="des_p">申请描述：{{list.apply_remark}}</div>
						<div class="date_p">{{list.created_at}}</div>		  			
					</div>	
				</div>
				</van-list>
		  </van-tab>

		  <van-tab title="已处理">
			  <van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoadPull"
				>
				<noDataPage v-if="isNoData" :text="noDataTxt" :url="noDataImg"></noDataPage>
				<div v-for="(list,id) in listTab1" :key="id">
					<div class="item_bd" @click="listToLink(list.type,list.id)" v-if="list.status!=1">
						<div class="h3_tit">
							{{list.title}}
							<van-tag class="h3_tag" plain color="#4EB240" type="primary" v-if="list.status==2">已通过</van-tag>
							<van-tag class="h3_tag" plain color="#F44545" type="success" v-if="list.status==3">已拒绝</van-tag>
						</div>
						<div class="des_p">申请理由：{{list.apply_reason}}</div>
						<div class="des_p">申请描述：{{list.apply_remark}}</div>
						<div class="date_p">{{list.created_at}}</div>		  			
					</div>
				</div>
				
				
			  </van-list>
		  </van-tab>


		  <van-tab title="抄送我" style="height:200px;">
			  	<noDataPage :text="noDataTxt" :url="noDataImg"></noDataPage>
				
		    	<!-- <div class="item_bd">
		  			<div class="h3_tit">
		  				抄送我抄送我抄送我
		  				<van-tag class="h3_tag" plain type="success">已通过</van-tag>
		  			</div>
		  			<div class="des_p">申请理由：不符合框架协议政策</div>
				  	<div class="des_p">申请描述：取得数据数据数为卡种名称，数量+金额…</div>
		  			<div class="date_p">06-12 08:15</div>		  			
		  		</div> 		  	 -->
		    	 
		  </van-tab>
		</van-tabs>
    
	    
		<bottomTabBar />


	</div>
</template>


<script>

import { Search } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Tab, Tabs } from 'vant';
import { Tag } from 'vant';	
import {sendPost} from '../assets/js/api.js'
import bottomTabBar from '@/components/bottomTabBar.vue'
import { List } from 'vant';
import noDataPage from '@/components/noDataPage.vue'

//Vue.use(Tab).use(Tabs);


export default {
  name: 'listTab',
  components: {
	bottomTabBar,noDataPage,
  	[Search.name]: Search,
  	[Tag.name]: Tag,
	  [Tab.name]: Tab,
	  [List.name]: List,
  	[Tabs.name]: Tabs,
  	[Cell.name]: Cell	 
	//[Tabbar.name]: Tabbar    	
	//  ,[TabbarItem.name]: TabbarItem  
  },
  data() {
    return {
		icon: {
			atMe: require('../assets/img/icon/icon-804.png'),//抄送 搜索
			mySearch: require('../assets/img/icon/icon-802.png')       
		},
	  isNoData:false,
	  noDataTxt:"",
	  noDataImg:"",
	  keyword:"",
	  active: 0,
      loading: false,
	  finished: false,
	  countee:1,
	  listTab:[],   
	   listTab1:[],
	   listTab2:[],
    }
  },
  beforeRouteEnter (to, from, next) {
	next(vm=>{
		vm.active=from.query.tab ? from.query.tab:0;
	});
  },
  methods: {

	  onLoadPull() {
		// 异步更新数据
		//this.requestListData(this.active+1,this.keyword,1);
		//setTimeout(() => {
			console.log("this.loading::::::"+this.loading);
			console.log("this.finished:::::"+this.finished);

			setTimeout(() => {
				this.requestListData(this.active+1,this.keyword,this.countee);
			}, 50);
			

			// 数据全部加载完成
			// if (this.list.length >= 40) {
			// this.finished = true;
			// }
		//}, 500);
		},
		
	  	onSearch(tab,key_word,page){//搜索事件
			  console.log(this.active);
			  console.log(this.keyword);
			  	this.listTab.length = 0;
				this.listTab1.length = 0;
				this.listTab2.length = 0;
				this.countee = 1;
				//console.log();
			  setTimeout(() => {
				  this.requestListData(this.active+1,this.keyword,this.countee);
			  }, 20);
			  //this.requestListData(this.active+1,this.keyword,this.countee);
		},
		onCancel(){//取消搜索
			  console.log("onCancel");
		},
		clearKeyWord(){//清除文字
				//console.log(this.active);
			 // console.log(this.keyword);
			  	this.keyword = "";
				this.listTab.length = 0;
				this.listTab1.length = 0;
				this.listTab2.length = 0;
				this.countee = 1;
			  this.requestListData(this.active+1,this.keyword,1);
		},
		searchKeyup(){//键盘输入事件
			 //console.log(this.active);
			  //console.log(this.keyword);
				this.listTab.length = 0;
				this.listTab1.length = 0;
				this.listTab2.length = 0;
				this.countee = 1;

			  setTimeout(() => {
				  this.requestListData(this.active+1,this.keyword,this.countee);
			  }, 200);
			  
			//onSearch(this.active,this.key_word);
		},
	  	listToLink(type,id){
			if(type==1){
				this.$router.push({path:'/reviewCard',query:{id:id,tab:this.active}});
			}else if(type==2){
				this.$router.push({path:'/reviewReject',query:{id:id,tab:this.active}});
			}  
			
		  },
		onChange(name, title) {
			//this.$toast(title);
			//console.log("name:"+name+"  title:"+title);
			//window.location.reload();
			this.active = name;
			this.keyword = "";
				this.listTab = [];
				this.listTab1 = [];
				this.listTab2 = [];
				this.countee = 1;
				//this.finished = false;
			//this.requestListData(name+1);
			// if(this.keyword!=''){
			// 	//this.onLoadPull(this.active+1,this.keyword,this.countee);
			//setTimeout(() => {
				console.log("rrrr");
				 //this.requestListData(this.active+1,this.keyword,this.countee);
				 this.onLoadPull();
			// }, 80);
	

			// }

		},
		onClickDisabled(){
			 this.$toast('暂未开放！');
		},
		requestListData(tab,key_word,page){
			sendPost("api/approval/list",{tab:tab,page:page,key_word:key_word}).then(res=>{
				this.isNoData = false;
				let _data = res.data;
				console.log(res);
				if(_data.code==0){
					if(tab==1){						
								//console.log("key_word333:::::");
								//console.log(_data.data.count);
								if(this.countee<_data.data.count){
									//console.log("rrrr");
									this.listTab = this.listTab.concat(_data.data.item);								
									this.countee = this.countee + 1;
								}else{
									this.finished = true;
								}
								this.loading=false;
								//数据为空是，显示暂无数据组件						
								if(this.countee<=1){
									if(_data.data.item.length>0){
										this.isNoData = false;
									}else{
										this.isNoData = true;
										if(key_word==''){
											this.noDataTxt = "暂无数据";
											this.noDataImg = this.icon.atMe;
										}else{
											this.noDataTxt = "抱歉，暂无搜索结果～";
											this.noDataImg = this.icon.mySearch;											
										}
										setTimeout(() => {
											if(document.getElementsByClassName("van-list__finished-text").length>0){
												for(let i = 0;i<document.getElementsByClassName("van-list__finished-text").length;i++){
														document.getElementsByClassName("van-list__finished-text")[i].innerText = '';
														//console.log(document.getElementsByClassName("van-list__finished-text"));
												}
											}
										 }, 100);

									}	
								}
						//}								 
						
					}else if(tab==2){						
						// 加载状态结束
						if(this.countee<_data.data.count){
							this.listTab1 =this.listTab1.concat(_data.data.item);								
							this.countee = this.countee + 1;
						}else{
							this.finished = true;
						}
						 this.loading=false;
						//数据为空是，显示暂无数据组件						
						if(this.countee<=1){
							if(_data.data.item.length>0){
								this.isNoData = false;
							}else{
								this.isNoData = true;
								if(key_word==''){
									this.noDataTxt = "暂无数据";
									this.noDataImg = this.icon.atMe;
								}else{
									this.noDataTxt = "抱歉，暂无搜索结果～";
									this.noDataImg = this.icon.mySearch;
								}

								if(document.getElementsByClassName("van-list__finished-text").length>0){
									for(let i = 0;i<document.getElementsByClassName("van-list__finished-text").length;i++){
											document.getElementsByClassName("van-list__finished-text")[i].innerText = '';
											//console.log(document.getElementsByClassName("van-list__finished-text"));
									}
								}
							}	
						}

					}else if(tab==3){
						//数据为空是，显示暂无数据组件						
						console.log("9999");
						this.isNoData = true;
						this.noDataTxt = "暂无抄送";
						this.noDataImg = this.icon.atMe;
					}
					//this.$toast(_data.msg);
				}else{
					this.$toast(res.data.msg);
				}						
			});
		} 
  },
  created(){
	  //this.requestListData(this.active+1,this.keyword,1);	  
  }
}	
</script>
<style scoped>
.search_box{background-color: #F5F6F9;padding: 10px 16px}
.search_box .van-search{ padding: 0 0 !important;}
.search_box .van-search__content{ background: #fff; border-radius: 5px;}
.search_box .van-search{border-radius: 5px;}

.item_bd{margin: 15px;padding: 0px 15px; border-radius: 4px; border: 0.5Px solid #e5e5e5;}
.item_bd .h3_tit{ 
	position: relative;   
    display:block;
    padding:10px 60px 5px 0;
    color: #323233;
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;
	font-weight: 600;
    }
    .h3_tag{ position: absolute; top: 10px; right: 0px;}
    .des_p,
    .date_p{    color: #999;
    font-size: 14px;
    height: 26px;
    line-height: 26px; overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;}
	.date_p{ text-align: right; padding-bottom: 5px;}

.van-tabs--line >>> .van-tabs__line{background-color:#307DCD;}


</style>




