<template>	
	<div class="no_data_page">
	  <van-icon class="icon_box" :name="url" />
	  <!-- <img class="icon_box" :src="url" /> -->
	  <div class="text_p">{{text}}<!--抱歉，暂无搜索结果～--></div>
	</div>
</template>


<script>

import { Cell, CellGroup } from 'vant';
import { Icon } from 'vant';

export default {
  name: 'noDataPage',
  components: {  
	  	[Icon.name]: Icon
  },
props: {
    text: String,
    url:String
    
  },
  data(){
	  return {
		 
		// icon:"warn-o",
		// text:"暂无数据"
	  }
  },
  methods: {
  	
  	// noDataPage(obj){
	// 	  this.icon = obj.icon;
	// 	  this.text = obj.text;
	// 	  console.log(obj.text);
	// }

	   
  },
  created(){
		 //this.noDataPage();
  }
}	
</script>
<style>
.no_data_page{ margin: 60px 30px; height: 300px; text-align: center;}	
.no_data_page .img{ width: 80px; height: 80px; display: block; margin: 0 auto;}
.no_data_page .icon_box{ margin: 0 auto; position: relative; left: 0; font-size: 60px; text-align: center; color: #D3D4D9;}
.no_data_page .icon_box::after{border-right:0;}
.text_p{ color: #ACB2BB; font-size: 16px; margin-top: 20px;}
</style>


    
