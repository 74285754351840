<template>	
	<div class="review" :class="{'revbot':detaillCon.status=='3'}">
		<div class="review_top">
			<van-cell size="large" center :title="priceTitle" :icon="avatar" />
			<van-tag class="review_status" v-if="detaillCon.status==1" plain color="#307DCD">审核中</van-tag>
			<van-tag class="review_status" v-if="detaillCon.status==2" plain color="#4EB240">已通过</van-tag>
			<van-tag class="review_status" v-if="detaillCon.status==3" plain color="#F44545">已拒绝</van-tag>
			<van-tag class="review_status" v-if="detaillCon.status==4" plain color="#F00065">已作废</van-tag>
			<van-tag class="review_status" v-if="detaillCon.status==5" plain color="#4EBfff">已过期</van-tag>
			<div class="review__txt" v-if="detaillCon.status==3">
				<van-cell title-class="tit" title="拒绝原因" value-class="red" :value="detaillCon.refuse_reason" />
			</div>
		</div>
		
		
		<van-cell-group title="申请人信息" class="group_bd">
			<van-cell title-class="tit" title="申请人" :value="detaillCon.user_name" />
			<van-cell title-class="tit" title="公司" :value="detaillCon.user_company" />
			<van-cell title-class="tit" title="所在部门" :value="detaillCon.user_department" />
		</van-cell-group>
		<van-cell-group title="守价信息" class="group_bd">
			<van-cell title-class="tit" value-class="red" title="总部" :value="detaillCon.enterprise_headquarters_name" />
			<van-cell title-class="tit" title="守价区域" :value="detaillCon.guard_price_area" />
			<van-cell title-class="tit" title="守价产品" value-class="red" :value="detaillCon.product_name" />
			<van-cell title-class="tit" value-class="red" title="最高配赠" v-if="detaillCon.max_allocation_ratio!='0.00'" :value="detaillCon.max_allocation_ratio+'%'" />
			<van-cell title-class="tit" value-class="red" title="最低单价" v-if="detaillCon.min_price!='0.00'" :value="detaillCon.min_price+'元'" />
			<van-cell title-class="tit" title="申请守价原因" :value="detaillCon.remarks" />
			<van-cell title-class="tit" title="守价有效期" v-if="detaillCon.status!=1 && detaillCon.status!=3" :value="detaillCon.guard_price_effective_date" />
		</van-cell-group>

		<div class="bottom_btn" v-if="detaillCon.status==1">
			<van-button plain hairline type="info" @click="auditReject">拒绝</van-button>
			<van-button class="right_btn" type="info" @click="auditPass">通过</van-button>
		</div>

	    
	</div>
</template>


<script>
import { Cell, CellGroup ,Tag,Tabbar,Button,Dialog } from 'vant';	
import {sendPost} from '../assets/js/api.js'

export default {
  name: 'review',
  components: {
  	[Cell.name]: Cell,
  	[CellGroup.name]: CellGroup,
	[Tag.name]: Tag,
	[Tabbar.name]: Tabbar,
	[Button.name]: Button,
	[Dialog.name]: Dialog
  },
  data (){
    return {
		myIcon: {
			noAvatar: require('../assets/img/icon/icon-803.png')				
		},		
		detaillCon:{},
		avatar:'',
    }
  },
  computed:{
	  priceTitle:function(){
		  let tit = (this.detaillCon.status == 1) ? '审核客户守价' : '客户守价审核详情';
		  return tit;
	  }
  },
  created (){
	  sendPost('api/approval/guardpricedetail',{id:this.$route.query.id}).then(res=>{
		let resData = res.data;
		if(resData.code == 0){
			this.detaillCon = resData.data;
		}else{
			this.$toast(res.data.msg);
		}	
	  });
	  this.avatar = localStorage.getItem('avatar') ? localStorage.getItem('avatar') : this.myIcon.noAvatar;
	  
  },
  methods: {
	editFun(){
		this.$router.push({path:'/form',query:{id:this.$route.query.id,title:'修改审批单'}});
	},
	getQueryString(name){
		var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
		var r = window.location.search.substr(1).match(reg);
		if(r!=null)return  unescape(r[2]); return null;
	},
	//审核通过
	auditPass(){
		let _id = this.getQueryString("id");
		Dialog.confirm({
			message: '你确定是否审核通过？',
			className:'viaalert'
		}).then(() => {
			sendPost('api/approval/guardpriceapproval',{id:_id,result:1}).then(res=>{
				if(res.data.code==0){
					//this.$toast(res.data.data.msg);						
					this.$router.push({path:'/listTabA'});						
				}else{							
					this.$toast(res.data.data);	
				}					
			});	
		}).catch(() => {
		// on cancel
		});
		
		
	},
	//审核拒绝
	auditReject(){

		let _id = this.getQueryString("id");
		this.$router.push({path:'/refuse',query:{id:_id,type:4}});	
		/*
		sendPost('api/approval/experience/card/audit/reject',{id:_id}).then(res=>{
			console.log(res);
			if(res.data.code==0){
				this.$toast(res.data.data.msg);						
				this.$router.push({path:'/listTab'});						
			}					
		});	
		*/
	},
  }
}	
</script>

<style scoped>
.review .van-cell-group__title{color:#333;font-size: 16px;}
.review_top .review__txt .tit{ flex: auto; width: 60px !important; color: #999;}
.review_top .review__txt .van-cell__value{flex:1 auto; width: 75% !important; text-align: left;color: #333;}
.red span{color:#f00;}
@media only screen and (width: 375px) and (min-height: 690px){
  .review_bottom{
    padding-bottom: 34px;
  }
 }
.bottom_btn{position: fixed; bottom: 0; left: 0; z-index: 9999; width: 100%; padding:10px 0; background: #fff;box-shadow:0px 0px 8px 0px rgba(0,0,0,0.1);}
.bottom_btn .van-button{ width: 40%; margin-left: 15px;}
.bottom_btn .van-button.right_btn{ float:right; margin-right: 15px;}
</style>





