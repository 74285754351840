import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const SET_TOKEN = 'SET_TOKEN'; // 用常量代替事件类型，使得代码更清晰
const REMOVE_TOKEN = 'REMOVE_TOKEN';

export default new Vuex.Store({
  state: {
		token:'',
    userID:'',
    showLoading:true
  },
  mutations: {
		updateLoading (state, showLoading) {
      state.showLoading = showLoading
    }
  },
  actions: {

  }
})
