<template>
  <div class="oa-index">
    <van-row type="flex" justify="space-between" >
			<van-col span="12" @click.native="pageToList">
				<img src="../assets/img/icon/icon-02.png" alt="">
				<span class="tag" v-if="sendRecordCount!=0">{{sendRecordCount}}</span>
				<h4>OA消息通知</h4>
			</van-col>
			<van-col span="12" v-if="isCheck"  @click.native="pageToCheck">
				<img src="../assets/img/icon/icon-01.png" alt="">
				<span class="tag" v-if="approveCount!=0">{{approveCount}}</span>
				<h4>OA审批</h4>
			</van-col>
			<!-- 布局  没有“OA审批”显示-->
			<van-col span="12" v-if="!isCheck && isSales==1" @click.native="pageToCard">
				<img src="../assets/img/icon/icon-card.png" alt="">
				<h4>合同卡签收</h4>
			</van-col>
		</van-row>
		<!-- 布局  有“OA审批”显示-->
		<van-row v-if="isCheck && isSales==1" type="flex" justify="space-between" style="margin-top:15px;">
			<van-col span="12" @click.native="pageToCard">
				<img src="../assets/img/icon/icon-card.png" alt="">
				<h4>合同卡签收</h4>
			</van-col>
			<van-col span="12" style="background: #F5F6F9;"></van-col>
		</van-row>
		<van-cell-group v-if="envIndex.NODE_ENV!='production'">
			<div class="tit_test">当前用户：<span class="name">{{curName}}</span><span class="id">ID:{{curId}}</span></div>
			<van-field label="用户Id" v-model="userId" placeholder="请输入Id" />
			<van-cell title="" />
			<van-button size="small" @click="changeUser()" type="info">提交</van-button>
		</van-cell-group>
  </div>



</template>

<script>
import { Field } from 'vant';
import { Button } from 'vant';
import { Cell,CellGroup } from 'vant';
import { Toast } from 'vant';

import { Row, Col , Tag } from 'vant';
import {sendPost} from '../assets/js/api.js'
export default {
  name: 'home',
  components: {
          [Cell.name]: Cell,
          [CellGroup.name]: CellGroup,  
          [Toast.name]: Toast,  
          [Field.name]: Field,
          [Button.name]: Button,

    [Row.name]: Row,
    [Col.name]: Col,
	[Tag.name]: Tag
	
  },
  data(){
	  return {		         
            curName:localStorage.getItem('name'),
            curId:"",
			userId:localStorage.getItem('user_id'),
			token:localStorage.getItem('token'),
			slug:localStorage.getItem('slug'),
		  sendRecordCount:'',
		  approveCount:'',
		  
			envIndex:process.env
	  }
  },
  computed:{
	  isCheck:function(){
		  if(!localStorage.getItem('slug')) return;
		  return localStorage.getItem('slug').split(',').includes('h5_approval_list') || localStorage.getItem('slug').split(',').includes('h5_approval_list_my');
	  },
	  isMy:function(){
		  if(!localStorage.getItem('slug')) return;
		  return localStorage.getItem('slug').split(',').includes('h5_approval_list_my');
	  }
  },
  created(){
	sendPost('api/notify/send/record/count').then(res=>{
		let resData = res.data;
		if(resData.code == 0){
			this.sendRecordCount = resData.data.sendRecordCount;
			this.approveCount = resData.data.approveCount;
			
		}
	});

	this.curName = localStorage.getItem('name');
	this.curId = localStorage.getItem('user_id');
	this.isSales = localStorage.getItem('isSales');
  },
  methods: {
	        changeUser(){
               console.log("提交");
               console.log(this.userId);
               let _that = this;
              // this.data.userId = userId;
               if(!this.userId){
                  Toast('请输入id');
               }else{
                    localStorage.removeItem('token');
                    sendPost('api/test/login',{'user_id':_that.userId}).then(function(res){
                        let resData = res.data;
                        if(resData.code == 0){
                          localStorage.setItem('token',res.data.data.token);
                          localStorage.setItem('name',res.data.data.name);
                          localStorage.setItem('role',res.data.data.role);
                          localStorage.setItem('user_id',res.data.data.user_id);
                          localStorage.setItem('avatar',res.data.data.avatar);
													localStorage.setItem('slug',res.data.data.slug);
													localStorage.setItem('isSales',res.data.data.isSales);
                          _that.curName = localStorage.getItem('name');
						  _that.curId = localStorage.getItem('user_id');
						  location.reload();
                        }
					  });
					  
               }
          },
	  pageToList(){
		  this.$router.push('/list');
		},
		pageToCard(){
			this.$router.push('/cardReceiptList');
		},
	  pageToCheck(){
		  if(this.isMy){
			  this.$router.push('/listTabA');
		  }else{
			  this.$router.push('/approval');
		  }
	  }
  }
}
</script>
<style>
.oa-index{
	padding:12px;
	background: #F5F6F9;
	min-height:100vh;
}
.oa-index .van-col{
	display:flex;
	flex-direction:column;
	height: 150px;
	background: #fff;
	justify-content:center;
	align-items:center;
	border-radius:4px;
	position:relative;
}
.oa-index .van-col .tag{
	min-width: 7px;
	min-height: 16px;
	display:flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	background: #F44545;
	color:#fff;
	border-radius: 22px;
	padding:2px 6px;
}
.oa-index .van-col .van-tag,.oa-index .van-col .tag{
	position:absolute;
	left:50%;
	margin-left:10px;
	top:22px;
}
.oa-index .van-col:first-child{
	margin-right:12px;
}
.oa-index .van-col img{
	width:60px;
	height:60px;
	margin-bottom:15px;
}
.oa-index .van-col h4{
	margin:0;
	font-weight:normal;
	font-size:16px;
}

.tit_test{ margin-top: 30px; font-size: 14px; padding: 20px 15px 20px 15px;}
.tit_test .name{ padding: 0 20px;}
</style>
