<template>	
	<div class="month_record">
		<van-tabs @click="changeTab" sticky title-active-color="#307DCD">
			<div v-for="monthStr in monthRecord.month" :key="monthStr.id">
				<van-tab  :title="monthStr">					
					<van-cell class="total_bd" title-class="total_tit" value-class="total_txt" title="合计金额" :value="'¥'+monthRecord.info.accounting_amount" />
					
					<div v-if="monthRecord.info.item.length==0" class="no_data_txt">当前月份暂无通过记录</div>
					<div v-if="monthRecord.type==0">
						<van-cell-group class="item_cell" v-for="item in monthRecord.info.item" :key="item.id">
							<van-cell class="date_cell" title-class="date_color" value-class="price_color"  :title="item.created_at" :value="'¥'+item.accounting_amount" />
							<van-cell class="bd_cell" title-class="com_name" label-class="label_color" :title="item.enterprise_name" :label="item.common_card_kind_name + item.quantity_and_amount" />
							<van-cell class="detail_link" title="查看详情" is-link :to="'/reviewCard?urlForm=1&id='+item.id" />				
						</van-cell-group>	
					</div>
					<div v-else-if="monthRecord.type==1">						
						<van-cell-group class="item_cell" v-for="item in monthRecord.info.item" :key="item.id">							
							<van-cell class="date_cell" title-class="date_color" value-class="price_color"  :title="item.created_at" :value="'¥'+item.accounting_amount" />
								<div class="icon_box">
									<span></span>
									<img v-if="item.user_avatar" class="img" :src="item.user_avatar" />
									<img v-else class="img" :src="myIcon.noAvatar" />									
									<div class="name">{{item.user_name}}</div>
								</div>							
							<van-cell class="bd_cell icon_cell" title-class="com_name" label-class="label_color" :title="item.enterprise_name" :label="item.common_card_kind_name + item.quantity_and_amount" />
							<van-cell class="detail_link" title="查看详情" is-link :to="'/reviewCard?urlForm=1&id='+item.id" />				
						</van-cell-group>	

					</div>

					<!-- 
					<van-cell-group class="item_cell">
						<van-cell class="date_cell" title-class="date_color" value-class="price_color"  title="2019-05-26 15" value="￥200" />
						<div class="icon_box">
							<img class="img" src="@/assets/img/icon/icon-18.png" />
							<div class="name">金King金</div>
						</div>
						<van-cell class="bd_cell icon_cell" title-class="com_name" label-class="label_color" title="上海东福网络科技有限公司上海东福网络科技有限公司" label="成都成都成都成都-东福超级卡（券）；2*100.00点" />						
						<van-cell class="detail_link" title="查看详情" is-link to="/Home" />				
					</van-cell-group>
					
					<van-cell-group class="item_cell">
						<van-cell class="date_cell" title-class="date_color" value-class="price_color"  title="2019-05-26 15" value="￥200" />
						<van-cell class="bd_cell" title-class="com_name" label-class="label_color" title="上海东福网络科技有限公司" label="成都-东福超级卡（券）；2*100.00点" />
						<van-cell class="detail_link" title="查看详情" is-link to="/Home" />				
					</van-cell-group> -->

					<!-- <van-cell-group class="item_cell" v-for="item in monthRecord.item" v-bind:key="item">
						<van-cell class="date_cell" title-class="date_color" value-class="price_color"  :title="" :value="item.accounting_amount" />
						<div class="icon_box">
							<img class="img" src="@/assets/img/icon/icon-18.png" />
							<div class="name">金King金</div>
						</div>
						<van-cell class="bd_cell icon_cell" title-class="com_name" label-class="label_color" title="上海东福网络科技有限公司" label="成都-东福超级卡（券）；2*100.00点" />
						<van-cell class="detail_link" title="查看详情" is-link to="/Home" />				
					</van-cell-group> -->			

				</van-tab>
			</div>

			
			


		</van-tabs>
	    
	    
	</div>
</template>


<script>
import { Cell, CellGroup } from 'vant';
import { Tab, Tabs } from 'vant';
import { Icon } from 'vant';
import {sendPost} from '../assets/js/api.js'
export default {
  name: 'monthRecord',
  components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Icon.name]: Icon,
		[Tab.name]: Tab,
		[Tabs.name]: Tabs  	
  
  },
  data(){
	  return {
			myIcon: {
				noAvatar: require('../assets/img/icon/icon-803.png')				
			},
		  monthRecord:{
					month:[],
					info:[]
		  		}		  
	  }
  },
  methods: {
		changeTab(index,title) {
			console.log("index");
			console.log(index);			
			console.log("title");
			console.log(title);
			this.monthRecordRequest(this.monthRecord.type,this.getQueryString("id"),title);		
		},
		monthRecordRequest(type,id,time){
			let _this = this;
			let	url = "";
			if(type==0){
				url="api/approval/experience/card/pass/record";
			}else if(type==1){
				url="api/approval/experience/card/auditor/record";
			}
			sendPost(url,{user:id,time:time}).then(res=>{
				console.log(res);
				let _data = res.data;
				if(_data.code==0){
					_this.monthRecord.info = _data.data;
					//_this.monthRecord.type=type;
					//console.log(_this.monthRecord);
				}else{
					this.$toast(res.data.msg);
				}						
			});	
		},
		formatNumber:function(n) {
			n = n.toString()
			return n[1] ? n : '0' + n
		},
		// 时间格式化
		formatTime:function(date,c) {
			let year = date.getFullYear()
			let month = date.getMonth() + 1

				if(month<=c){
					year = year -1;
					month = month - c + 12
				}else{
					month = month - c
				}			
			return [year, month].map(this.formatNumber).join('-')
		},
		getQueryString(name){
			var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
			var r = window.location.search.substr(1).match(reg);
			if(r!=null)return  unescape(r[2]); return null;
		}
	},
	created:function(){
		let _id = this.getQueryString("id");
		let _type = this.getQueryString("type");
		let  _nowDate = new Date();
		let _timeTxt = "";
		
		if(_nowDate.getMonth()+1<10){
			_timeTxt = _nowDate.getFullYear() + "-0" + (_nowDate.getMonth()+1);
		}else{
			_timeTxt = _nowDate.getFullYear() + "-" + (_nowDate.getMonth()+1);
		}
		//this.monthRecord.month.push(_timeTxt);

		for(var i=0;i<6;i++){
			//console.log(i);
			let monthNum,yearNum;
			if(_nowDate.getMonth()+1-i>0){
				//console.log(_nowDate.getMonth()+1-i+":::i="+i);
				monthNum = _nowDate.getMonth()+1-i;
				if(monthNum<10){
					monthNum = "-0" + monthNum;
				}else{
					monthNum = "-" + monthNum;
				}
				yearNum = _nowDate.getFullYear();
			}else{
				//console.log(_nowDate.getMonth()+1-i+12+":::i="+i); 
				monthNum = _nowDate.getMonth()+1-i+12;
				if(monthNum<10){
					monthNum = "-0" + monthNum;
				}else{
					monthNum = "-" + monthNum;
				}
				yearNum = _nowDate.getFullYear() - 1;
			}
		
			_timeTxt = yearNum + monthNum;
			this.monthRecord.month.push(_timeTxt);
			//console.log(_timeTxt);

		}	
		this.monthRecord.type=_type;
		console.log(this.monthRecord);

		this.monthRecordRequest(_type,_id,this.monthRecord.month[0]);

	}

}	
</script>
<style >
.month_record .van-tabs__line{background-color:#307DCD !important;}
.item_cell{ border-top: 10px solid #F5F6F9;}
.total_tit{ color:#333333;}
.total_txt{ color:#f44545;}
.date_color{ color:#999999;}
.price_color{ color:#333;}
.com_name{ color:#333; font-weight: bold;white-space:nowrap; overflow: hidden;text-overflow:ellipsis;}
.label_color{ color:#666; font-size: 14px; font-weight: normal;white-space:nowrap; overflow: hidden;text-overflow:ellipsis;}
.date_cell{ padding: 10px 15px 0 15px;}
.date_cell.van-cell:not(:last-child)::after{border-bottom:0;}
.detail_link .van-cell__title{ color: #999}
.item_cell.van-hairline--top-bottom::after{ border-width: 0 0 !important;}
.bd_cell {padding-top:5px;}
.bd_cell.van-cell:not(:last-child)::after{right: 15px;}
.no_data_txt{padding-top:100px; text-align: center; color: #ACB2BB; font-size: 16px;}
.total_bd.van-cell:not(:last-child)::after{ left: 0;}

.bd_cell.icon_cell {padding-top:5px; padding-left: 90px;}
.icon_box{ position:absolute;z-index: 5; left: 15px; width:60px; height:60px; }
.icon_box::after{ position: absolute;right:-5px;top:-10px; height:78px; box-sizing: border-box; content: ' '; pointer-events: none; border-right: 1px solid #ebedf0; -webkit-transform: scaleY(.5);transform: scaleY(.5);}
.icon_box .img{ display: block; margin:0 auto; width: 30px; height: 30px;}
.icon_box .name{ width: 100%; padding-top: 5px; text-align: center; font-size: 12px; white-space:nowrap; overflow: hidden;text-overflow:ellipsis;}

</style>




