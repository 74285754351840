import axios from 'axios'
import { Toast } from 'vant';
import store from '../../store'
import { platformName } from '../../router'
import dd from 'dingtalk-jsapi'

/**
 * 创建一个axios实例
 */
const _axios = axios.create();// 请求前添加拦
const baseUrl = process.env.VUE_APP_BASE_URL;
if (!localStorage.getItem('token') && process.env.NODE_ENV == 'development') {
  clearInit();
  sendPost('api/user/login',{'user_id':61}).then(function(res){
    let resData = res.data;
    if(resData.code == 0){
      localStorage.setItem('token',res.data.data.token);
      localStorage.setItem('name',res.data.data.name);
      localStorage.setItem('role',res.data.data.role);
      localStorage.setItem('user_id',res.data.data.user_id);
      localStorage.setItem('avatar',res.data.data.avatar);
      localStorage.setItem('slug',res.data.data.slug);
      localStorage.setItem('isSales',res.data.data.isSales);
      location.reload();
    }else{
      Toast(res.data.msg);
    }
  });
}
// http request 拦截器
_axios.interceptors.request.use(
	config => {
    if (localStorage.getItem('token')) {
      config.headers.token = localStorage.getItem('token');
    }
    store.commit('updateLoading', true);
	return config
	},
	error => {
	console.log(error) 
	Promise.reject(error)
	}
);

function login() {
  sendPost('api/user/login',{'code':getQueryString('code')}).then(function(res){
    let resData = res.data;
    if(resData.code == 0){
      clearInit();
      localStorage.setItem('token',res.data.data.token);
      localStorage.setItem('name',res.data.data.name);
      localStorage.setItem('role',res.data.data.role);
      localStorage.setItem('isSales',res.data.data.isSales);
      localStorage.setItem('user_id',res.data.data.user_id);
      localStorage.setItem('avatar',res.data.data.avatar);
      localStorage.setItem('slug',res.data.data.slug);
      localStorage.setItem('time',curTime);
      next();
      location.reload();
    }else{
      promptMsg(resData.msg);
    }
  });


}
_axios.interceptors.response.use(
  response => {
    let code = response.data.code;
    if(code != 0 && (code==5010001||code==5010003 || code == 5010008 )){
      
      let baseUrlTwo=process.env.VUE_APP_ROUTER_URL;
      let path = (location.pathname=='/') ? '/index' : location.pathname;
        if (platformName === 'wecom') {
          clearInit()
          if(process.env.NODE_ENV != 'development' && !location.search.includes('code=')){
            const ssoRedirect = encodeURI(
              `${process.env.VUE_APP_SSO_URL}/callback/dingtalk?tag=crm@@${encodeURIComponent(baseUrlTwo+path+location.search)}`
              )
            let url='https://open.weixin.qq.com/connect/oauth2/authorize?appid='+process.env.VUE_APP_APP_ID+'&redirect_uri='+encodeURIComponent(baseUrlTwo+path+location.search)+'&response_type=code&scope=snsapi_userinfo&agentid='+process.env.VUE_APP_AGENT_ID+'#wechat_redirect';
            location.href=url;
          }
          if(process.env.NODE_ENV != 'development' && location.search.includes('code=') && response.request.requestUrl.indexOf('login') === -1){
            login()
          }
        } else {
          const ssoRedirect = `${process.env.VUE_APP_SSO_URL}/callback/dingtalk?tag=crm@@${encodeURIComponent(baseUrlTwo+path)}`
            
          if (response.config.url.indexOf('login') === -1 ){
            if (dd.env.platform === 'notInDingTalk') {
              if (document.cookie.indexOf(process.env.VUE_APP_COOKIE_KEY) === -1) {
                location.href = process.env.VUE_APP_SSO_URL
                // next()
              } else {
                login()
              }
            } else { // in dingtalk
              if (document.cookie.indexOf(process.env.VUE_APP_COOKIE_KEY) === -1) {
                dd.ready(function() {
                  dd.runtime.permission.requestAuthCode( {
                    corpId: process.env.VUE_APP_DINGTALK_CORP_ID,
                  }).then(info => {
                    window.location.href = ssoRedirect + '&code=' + info.code
                  }).catch(e => {
                    console.log('auth error in dingtalk', e)
                  })
                })
                dd.error((err) => {
                  console.log('dd error: ', err)
                })
              } else {
                login()
              }
            }
          }else {
            if (platformName === 'dingtalk') {
              dd.ready(function() {
                dd.runtime.permission.requestAuthCode( {
                  corpId: process.env.VUE_APP_DINGTALK_CORP_ID,
                }).then(info => {
                  window.location.href = ssoRedirect + '&code=' + info.code
                }).catch(e => {
                  console.log('auth error in dingtalk', e)
                })
              })
              dd.error((err) => {
                console.log('dd error: ', err)
              })
            } else {
              location.href = process.env.VUE_APP_SSO_URL
            }
            
          }
        }
    } 
    store.commit('updateLoading', false);
    return response;
  },
  error => {
    if(error && (error.response.status != 200)){
      store.commit('updateLoading', false);
      Toast('系统错误'+error.response.status);
      return;
    }
    if(error && (error.response.request.timeout >= 6000)){
      store.commit('updateLoading', false);
      Toast('请求超时');
    }
    console.log(error);
	  Promise.reject(error);
  });

// 上产文件设置12s请求超时
export function sendPostFile(url, params) {
  return _axios({
    url: baseUrl+ url,
    method: "post",
    data: params,
    // timeout: 12000,
    withCredentials: true
  }).catch(err => {
    console.log(err);
  });
}
export function sendPost(url, params) {
  return _axios({
    url: baseUrl+ url,
    method: "post",
    data: params,
    timeout: 6000,
    withCredentials: true
  }).catch(err => {
   console.log(err);
  });
}

export function sendGet(url) {
  return _axios({
    url: baseUrl+ url,
    method: "get",
    timeout: 6000,
    withCredentials: true
  }).catch(err => {
   console.log(err);
  });
}
export function promptMsg(msg){
  Toast({
    message: msg,
    icon: 'warning-o',
    duration:1500
  });
}
export function passMsg(msg){
  Toast({
    message: msg,
    icon: 'passed',
    duration:1500
  });
}
export function clearInit(){
  localStorage.removeItem('token');
  localStorage.removeItem('name');
  localStorage.removeItem('role');
  localStorage.removeItem('isSales');
  localStorage.removeItem('user_id');
  localStorage.removeItem('avatar');
  localStorage.removeItem('slug');
  localStorage.removeItem('time');
}

/**
 * 获取请求参数名对应的值
 * @param  {String} name 参数名字
 * @return {String}
 */
export function getQueryString(name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const res = window.location.search.substr(1).match(reg);
  if (res !== null) return window.decodeURIComponent(res[2]);
  return "";
}