<template>	
	<div class="listTab">
		<div class="search_box">
			<van-search placeholder="搜索人名、标题、内容、状态" />
		</div>
		
		 

		 
		<div v-for="list in myList.item" :key="list.id">
			<div class="item_bd" @click="listToLink(list.type,list.id)">
				<div class="h3_tit">
					{{list.title}}
					<van-tag class="h3_tag" plain color="#307DCD" v-if="list.status==1">审批中</van-tag>
					<van-tag class="h3_tag" plain color="#4EB240" type="primary" v-if="list.status==2">已通过</van-tag>
					<van-tag class="h3_tag" plain color="#F44545" type="success" v-if="list.status==3">已拒绝</van-tag>
				</div>
				<div class="des_p">申请理由：{{list.apply_reason}}</div>
				<div class="des_p">申请描述：{{list.apply_remark}}</div>
				<div class="date_p">{{list.created_at}}</div>		  			
			</div> 
		</div>		
    
	    
		<bottomTabBar />


	</div>
</template>


<script>

import { Search } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Tag } from 'vant';	
import {sendPost} from '../assets/js/api.js'
import bottomTabBar from '@/components/bottomTabBar.vue'


//Vue.use(Tab).use(Tabs);


export default {
  name: 'searchList',
  components: {
	bottomTabBar,
  	[Search.name]: Search,
  	[Tag.name]: Tag,  
  	[Cell.name]: Cell	 
	//[Tabbar.name]: Tabbar    	
	//  ,[TabbarItem.name]: TabbarItem  
  },
  data() {
    return {
	  //active: 0,
	  myList:{
	// 	  count:28,
	// 	  items:[
	// 		  {
	// 			  apply_reason:"w",
	// 			  apply_remark:"5月6分支机构,礼包系统后台下单卡,2 * 100点",
	// 			  created_at:"2019年07月15 15:02",
	// 			  id:"176",
	// 			  status:"1",
	// 			  title:"湖北销售2的体验卡审批单",
	// 			  type:"1"
	// 		   }
	// 	  ]
	   }
    }
  },
  methods: {
	  	listToLink(type,id){
			if(type==1){
				this.$router.push({path:'/reviewCard',query:{id:id}});
			}else if(type==2){
				this.$router.push({path:'/review',query:{id:id}});
			}  
			
		  },
		requestMyListData(page){
			sendPost("api/approval/mylist",{page:page}).then(res=>{
				console.log(res);
				let _data = res.data;
				// if(_data.code==0){
				// 	if(tab==1){
				// 		this.listTab = _data.data;					
				// 		console.log(this.listTab);
				// 	}else if(tab==2){
				// 		this.listTab1 = _data.data;					
				// 		console.log(this.listTab1);
				// 	}else if(tab==3){
				// 		this.listTab2 = _data.data;					
				// 		console.log(this.listTab2);
				// 	}


				// }					
			});


		}




  	
	   
  },
  created(){
	  this.requestMyListData(1);
  }
}	
</script>
<style scoped>
.search_box{background-color: #F5F6F9;padding: 10px 16px}
.search_box .van-search{ padding: 0 0 !important;}

.item_bd{margin: 15px;padding: 0px 15px; border-radius: 4px; border: 1px solid #ccc;}
.item_bd .h3_tit{ 
	position: relative;   
    display:block;    
    
    padding:10px 60px 5px 0;
    
    color: #323233;
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;
	font-weight: 600;
    }
    .h3_tag{ position: absolute; top: 10px; right: 0px;}
    .des_p,
    .date_p{    color: #999;
    font-size: 14px;
    height: 26px;
    line-height: 26px; overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;}
	.date_p{ text-align: right; padding-bottom: 5px;}


.van-tabs--line >>> .van-tabs__line{background-color:#307DCD;}


</style>




