<template>	
	<div class="review">
		<div class="review_top" v-if="reviewCarData.info.status==1">
			<div class="title_top">
				<span v-if="reviewCarData.info.user_avatar==''">
				<van-cell size="large" center title="我的体验卡审批单"  :icon="myIcon.noAvatar" />
				</span>
				<span v-else>
				<van-cell size="large" center title="我的体验卡审批单" :icon="reviewCarData.info.user_avatar" />
				</span>
				<van-tag class="review_status" plain color="#307DCD">审批中</van-tag>
			</div>
			
		</div>
		<div class="review_top" v-else-if="reviewCarData.info.status==2">
			<div class="title_top">
				<span v-if="reviewCarData.info.user_avatar==''">
				<van-cell size="large" center title="我的体验卡审批单"  :icon="myIcon.noAvatar" />
				</span>
				<span v-else>
				<van-cell size="large" center title="我的体验卡审批单" :icon="reviewCarData.info.user_avatar" />
				</span>	
				<van-tag class="review_status" plain color="#4EB240">已通过</van-tag>
			</div>			
		</div>

		<div class="review_top" v-else-if="reviewCarData.info.status==3">
			<div class="title_top">
				<span v-if="reviewCarData.info.user_avatar==''">
				<van-cell size="large" center title="我的体验卡审批单"  :icon="myIcon.noAvatar" />
				</span>
				<span v-else>
				<van-cell size="large" center title="我的体验卡审批单" :icon="reviewCarData.info.user_avatar" />
				</span>
				<van-tag class="review_status" plain color="#F44545">已拒绝</van-tag>
				<div class="review__txt">
					<van-cell title-class="tit" title="拒绝原因" :value="reviewCarData.info.reject_reason" />
				</div>
			</div>			
		</div>
		
		<!-- <van-cell-group title="申请人信息" class="group_bd">
		  <van-cell title-class="tit" title="发起人" :value="reviewCarData.info.user_name" />
		  <van-cell title-class="tit" title="城市" :value="reviewCarData.info.user_company" />
		  <van-cell title-class="tit" title="所属部门" :value="reviewCarData.info.user_department" />
		</van-cell-group>			 -->
		
		<van-cell-group title="客户信息" class="group_bd">
		  <van-cell title-class="tit" title="客户名称" :value="reviewCarData.info.enterprise_name" />
		  <van-cell title-class="tit" title="申请理由" :value="reviewCarData.info.apply_reason" />
		</van-cell-group>
		
		<van-cell-group title="产品信息" class="group_bd">

			<div v-if="reviewCarData.info.status==1||reviewCarData.info.status==2||reviewCarData.info.status==3">
				<van-cell title-class="tit" title="业务编码" :value="reviewCarData.info.business_no" />
			</div>

			<div v-if="reviewCarData.info.card_type==1">		
				<van-cell title-class="tit" title="使用类型" value="点卡" />
			</div>
			<div v-else-if="reviewCarData.info.card_type==2">		
				<van-cell title-class="tit" title="使用类型" value="次卡" />
			</div>

			<!-- <van-cell title-class="tit" title="选择卡种" :value="reviewCarData.info.common_card_kind_name" />			
			<van-cell title-class="tit" title="单张卡内金额" :value="'¥'+reviewCarData.info.card_amount" />
			<van-cell title-class="tit" title="卡数量" :value="reviewCarData.info.card_num" />
			<van-cell class="total" title-class="tit" title="核算金额" value-class="" :value="'¥'+reviewCarData.info.accounting_amount" /> -->
		
		

			<van-cell title-class="tit" title="选择卡种" :value="reviewCarData.info.common_card_kind_name" />
			<van-cell title-class="tit" v-if="reviewCarData.info.card_type==1" title="单张卡内金额" :value="'¥'+reviewCarData.info.card_amount" />
			<van-cell title-class="tit" v-else title="单张卡内次数" :value="reviewCarData.info.card_amount" />
			<van-cell title-class="tit" title="卡数量" :value="reviewCarData.info.card_num" />
			<van-cell v-if="reviewCarData.info.status==2" title-class="tit" title="卡段" :value="reviewCarData.info.start_card_no+'-'+reviewCarData.info.end_card_no" />
			<van-cell v-if="reviewCarData.info.card_type==2" title-class="tit" title="核算单价" :value="'¥'+reviewCarData.info.account_price" />			
			<van-cell class="total" title-class="tit" title="核算金额" value-class="" :value="'¥'+reviewCarData.info?reviewCarData.info.accounting_amount:''" />
		
		
		
		
		</van-cell-group>	
		
		

	    	<!-- <div class="total_h3">近30天体验卡金额情况</div>
			<van-cell :title="reviewCarData.info.user_name+'共申请通过'" is-link :value="reviewCarData.total.apply_accounting_amount" @click="linkTo(0)" />
			<van-cell title="您共审批通过" is-link :value="reviewCarData.total.auditor_accounting_amount" @click="linkTo(1)" />
	     -->
		
		
			    
	    <div class="review_bottom">
	    	<van-cell-group title="审批流程" size="large" class="review_status" v-if="reviewCarData.info.status==1">
			  	<img class="img" src="@/assets/img/icon/icon-08.png">
				<span class="status_right" v-if="reviewCarData.info.auditor_avatar==''">
					<van-cell title-class="" :title="reviewCarData.info.auditor_name + ' • 审批中'"  :value="reviewCarData.info.updated_at" :icon="myIcon.noAvatar" />
				</span>
				<span class="status_right" v-else>
					<van-cell title-class="" :title="reviewCarData.info.auditor_name + ' • 审批中'"  :value="reviewCarData.info.updated_at" :icon="reviewCarData.info.auditor_avatar" />
				</span>			
			</van-cell-group>

			<van-cell-group title="审批流程" size="large" class="review_status" v-if="reviewCarData.info.status==2">
			  <img class="img" src="../assets/img/icon/icon-09.png">
				<span class="status_right" v-if="reviewCarData.info.auditor_avatar==''">
					<van-cell title-class="" :title="reviewCarData.info.auditor_name + ' • 已通过'" :value="reviewCarData.info.updated_at" :icon="myIcon.noAvatar" />
				</span>
				<span class="status_right" v-else>
					<van-cell title-class="" :title="reviewCarData.info.auditor_name + ' • 已通过'" :value="reviewCarData.info.updated_at" :icon="reviewCarData.info.auditor_avatar" />
				</span>
			</van-cell-group>

			<van-cell-group title="审批流程" size="large" class="review_status" v-if="reviewCarData.info.status==3">
				<img class="img" src="../assets/img/icon/icon-10.png">
				<span class="status_right" v-if="reviewCarData.info.auditor_avatar==''">
					<van-cell title-class="" :title="reviewCarData.info.auditor_name + ' • 已拒绝'" :value="reviewCarData.info.updated_at" :icon="myIcon.noAvatar" />
				</span>
				<span class="status_right" v-else>
					<van-cell title-class="" :title="reviewCarData.info.auditor_name + ' • 已拒绝'" :value="reviewCarData.info.updated_at" :icon="reviewCarData.info.auditor_avatar" />
				</span>
			</van-cell-group>
	    </div>

		
		<!-- <div class="bottom_btn" v-if="reviewCarData.info.status==1">
			<van-button plain hairline type="info" @click="auditReject">拒绝</van-button>
			<van-button class="right_btn" type="info" @click="auditPass">通过</van-button>
		</div> -->
	    
	    
	    
	</div>
</template>


<script>
import { Cell, CellGroup } from 'vant';
import { Tag } from 'vant';	
import { Button } from 'vant';
import { Dialog } from 'vant';

import {sendPost} from '../assets/js/api.js'

export default {
  name: 'mySubmitDetail',
  components: {
  	[Cell.name]: Cell,
  	[CellGroup.name]: CellGroup,
	  [Tag.name]: Tag,
	  [Button.name]: Button,
	  [Dialog.name]: Dialog
  
  },
data (){
    return {
		myIcon: {
			noAvatar: require('../assets/img/icon/icon-803.png')				
		},		
		reviewCarData:{
			info:[],
			total:[]
		}
    }
  },
  methods:{
	  	reviewCard(){			
				this.reviewCardRequest();
				//this.reviewCardTotal();
		  },
			getQueryString(name){
					var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
					var r = window.location.search.substr(1).match(reg);
					if(r!=null)return  unescape(r[2]); return null;
		   },
		  reviewCardRequest(){
				  let _id = this.getQueryString("id");
				  console.log(this.$route.query.send_record);
				sendPost('api/approval/experience/card/show',{id:_id,send_record:this.$route.query.send_record}).then(res=>{
					console.log(res);
					if(res.data.code==0){
						let _user_id = localStorage.getItem("user_id");
						this.reviewCarData.info = res.data.data;
						localStorage.setItem("enterprise_name",res.data.data.enterprise_name);
						localStorage.setItem("apply_reason",res.data.data.apply_reason);	
						this.reviewCardTotal(res.data.data.user_id,_user_id)

					}					
				});	
		  },
		  reviewCardTotal(a,b){
				sendPost('api/approval/experience/card/countaccountingamount',{applicant:a,auditor:b}).then(res=>{
					console.log(res);
					if(res.data.code==0){
						this.reviewCarData.total = res.data.data;	


					}					
				});	
		  },
		  //审核通过
		  auditPass(){
			  let _id = this.getQueryString("id");
			  Dialog.confirm({
					message: '你确定是否审核通过？',
					className:'viaalert'
				}).then(() => {
					sendPost('api/approval/experience/card/audit/success',{id:_id}).then(res=>{
						console.log(res);
						if(res.data.code==0){
							//this.$toast(res.data.data.msg);						
							this.$router.push({path:'/listTabA'});						
						}else{							
							this.$toast(res.data.data);	
						}					
					});	
				}).catch(() => {
				// on cancel
				});
			 
				
		  },
		   //审核拒绝
		  auditReject(){

				let _id = this.getQueryString("id");
				this.$router.push({path:'/refuse',query:{id:_id,type:2}});	
			  /*
				sendPost('api/approval/experience/card/audit/reject',{id:_id}).then(res=>{
					console.log(res);
					if(res.data.code==0){
						this.$toast(res.data.data.msg);						
						this.$router.push({path:'/listTab'});						
					}					
				});	
				*/
		  },
		   linkTo(type){
				var _pass_url = "";//localStorage.getItem('user_id');//用户id 申请人体验卡记录
				
				if(type==0){
					_pass_url = "/monthRecord?type=0&id="+this.reviewCarData.info.user_id;

				}else if(type=1){
					_pass_url = "/monthRecord?type=1&id="+localStorage.getItem('user_id');
				}
				this.$router.push({path: _pass_url});

		   }
  	
	   
  },
  created (){
	  console.log(localStorage.getItem('user_id'));
		



		this.reviewCard()

	 






	
  }
}	
</script>
<style scoped="">
.bottom_btn{position: fixed; bottom: 0; left: 0; z-index: 9999; width: 100%; padding:10px 0; background: #fff;box-shadow:0px 0px 8px 0px rgba(0,0,0,0.1);}
.bottom_btn .van-button{ width: 40%; margin-left: 15px;}
.bottom_btn .van-button.right_btn{ float:right; margin-right: 15px;}

.group_bd .van-cell{ padding: 5px 15px;}
.review_top {border-bottom: 1px solid #ebedf0;margin-bottom: 10px;}
.review_top .title_top{ /*padding-right: 60px;*/ position: relative; }
.review_top .review_status{ position: absolute; top: 13px; right: 15px;}
.review_top .van-cell:not(:last-child)::after{ border-bottom: 0;}
.review_top .review__txt .van-cell__title.tit{ flex: auto; padding-right: 0; width: 60px !important; color: #999;}
.review_top .review__txt .van-cell__value{flex: auto; width: 75% !important; text-align: left;}
.review_top .title_top .van-cell__title{padding-right: 60px;}

.group_bd{ border-bottom: 15px solid #f5f6f9}
.group_bd .van-hairline--top-bottom::after{ border-width:0 0;}
.group_bd .van-cell:not(:last-child)::after{border-bottom:0;}
.group_bd.van-hairline--top-bottom::after{border-width:0 0 !important;}

.group_bd .van-cell__title, 
.group_bd .van-cell__value{ flex:auto !important;}
.group_bd .van-cell__title{ width:60px !important; color: #999;}
.group_bd .van-cell__value{ width: 75% !important; padding-left: 10px; text-align: left; color: #333;}

.van-cell-group__title{ color: #333; font-weight: bold;margin-bottom: 5px;}

.group_bd .van-cell.total{ border-top: 0.5Px solid #efefef; margin-top: 5px; padding: 11px 15px;}
.group_bd .van-cell.total .van-cell__value{ color: #F44545;}

.review_bottom{ padding-bottom: 60px;border-top:15px;}
.review_bottom .van-cell-group__title{color: #333;}
.total_h3{ background:#F5F6F9; padding:5px 15px 12px 15px; color:#999; font-size:12px;}
.review_status{ display: flex;}
.review_status .img{flex: 1 auto; margin-left: 20px; margin-top: 15px; width:18px; height: 18px;}

.review_status .status_right{ display: flex; flex: 1 auto; width: 100%;}
.review_status .van-cell__left-icon{ font-size: 26px;}
.review .van-button{line-height: 40px;height:40px;font-size:15px;}
.viaalert .van-dialog__content{padding-top:75px;background:url(../assets/img/icon/icon-07.png) 50% 20px no-repeat;background-size:95px auto;}
.review .review_top .van-cell__value{color:#333;}

</style>




