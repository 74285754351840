<template>
  <div class="oa-approval">
    <van-row type="flex">
		  <van-col span="8">
		  	<a href="/form">
		  		<img src="../assets/img/icon/icon-06.png" alt="">
		  	</a>
		  	<h4>申请特批单</h4>
		  	<!-- <p>（下单时用）</p> -->
		  </van-col>
		</van-row>
		
		<bottomTabBar activeNum="/listTabA" />
  </div>
</template>

<script>
import { Row, Col } from 'vant';
import bottomTabBar from '@/components/bottomTabBar.vue'

export default {
  name: 'home',
  components: {
	  bottomTabBar,
    [Row.name]: Row,
    [Col.name]: Col
   
  },
  data() {
    return {
     
    }
  }
}
</script>
<style>
.oa-approval{
	padding:12px;
	background: #F5F6F9;
	min-height:96vh;
}
.oa-approval .van-col{
	display:flex;
	flex-direction:column;
	height: 120px;
	background: #fff;
	justify-content:center;
	align-items:center;
	border-radius:4px;
}
.oa-approval .van-col img{
	width:42px;
	height:42px;
	margin-bottom:10px;
}
.oa-approval .van-col h4{
	margin:0;
	font-weight:normal;
	font-size:12px;
	color:#333;
}
.oa-approval .van-col p{
	margin:0;
	font-size:12px;
	color:#999;
}
</style>
