<template>
  <div class="home">
    <h2>目录</h2>
    <van-cell-group>
      <van-cell title="首页" to="/index" is-link right-icon />
      <van-cell title="消息列表" to="/list" is-link right-icon />
      <van-cell title="OA审批" to="/approval" is-link right-icon />
      <van-cell title="申请特批单" to="/form" is-link right-icon />
      <van-cell title="审批拒绝" to="/refuse" is-link right-icon />   
      <van-cell title="审批结果详情--申请人" to="/review" is-link right-icon />
      <van-cell title="审批结果详情--审批人" to="/reviewReject" is-link right-icon />
      
      
      
      
      
      
      
      
      
      
      <van-cell title="审批结果详情--拒绝--我的体验卡" to="/reviewRejectCard" is-link right-icon />
      <van-cell title="消息详情" to="/messageDetail" is-link right-icon />
      <van-cell title="我审批的" to="/listTabA" is-link right-icon />
      <!-- <van-cell title="消息详情" to="/messageDetail" is-link right-icon /> -->
      <van-cell title="体验卡通过记录" to="/monthRecord" is-link right-icon />
      <div>{{xx}}</div>
      
      
      
      
      
      
      
      
      
    </van-cell-group>
  </div>
</template>

<script>
import { Cell, CellGroup } from 'vant';

export default {
  name: 'home',
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data(){
    return {
      xx:''
    }
  },
  created(){
    this.xx = localStorage.getItem('haha');
  }
}
</script>
<style>
.home{
  padding:0 15px;
}
.home h2{
  text-align: center;
  padding:20px 0;
}

</style>