<template>	
	<div class="review">
		<div class="review_top" v-if="reviewCarData.info.status==1">
			<div class="title_top">
				<span v-if="reviewCarData.info.applicantAvatar==''">
				<van-cell size="large" center :title="reviewCarData.info.title" :icon="myIcon.noAvatar" />
				</span>
				<span v-else>
				<van-cell size="large" center :title="reviewCarData.info.title" :icon="reviewCarData.info.applicantAvatar" />
				</span>
				<van-tag class="review_status" plain color="#307DCD">申请中</van-tag>
			</div>
			
		</div>
		<div class="review_top" v-else-if="reviewCarData.info.status==2">
			<div class="title_top">
				<span v-if="reviewCarData.info.applicantAvatar==''">
				<van-cell size="large" center :title="reviewCarData.info.title"  :icon="myIcon.noAvatar" />
				</span>
				<span v-else>
				<van-cell size="large" center :title="reviewCarData.info.title" :icon="reviewCarData.info.applicantAvatar" />
				</span>	
				<van-tag class="review_status" plain color="#4EB240">已通过</van-tag>
			</div>			
		</div>

		<div class="review_top" v-else-if="reviewCarData.info.status==3">
			<div class="title_top">
				<span v-if="reviewCarData.info.applicantAvatar==''">
				<van-cell size="large" center :title="reviewCarData.info.title"  :icon="myIcon.noAvatar" />
				</span>
				<span v-else>
				<van-cell size="large" center :title="reviewCarData.info.title" :icon="reviewCarData.info.applicantAvatar" />
				</span>
				<van-tag class="review_status" plain color="#F44545">已作废</van-tag>
			</div>			
		</div>
		
		<van-cell-group title="申请人信息" class="group_bd">
		  <van-cell title-class="tit" title="申请人" :value="reviewCarData.info.user_name" />
		  <van-cell title-class="tit" title="所属城市" :value="reviewCarData.info.user_company" />
		  <van-cell title-class="tit" title="所属部门" :value="reviewCarData.info.user_department" />
		  <van-cell title-class="tit" title="申请提前判定时间" :value="reviewCarData.info.apply_early_determine_time" />
		  <van-cell title-class="tit" title="申请提前判定原因" :value="reviewCarData.info.apply_reason" />
		</van-cell-group>			
		
		<van-cell-group title="报备客户信息" class="group_bd">
		  <van-cell title-class="tit" title="报备客户编号" :value="reviewCarData.info.business_no" />
		  <van-cell title-class="tit" title="客户名称" :value="reviewCarData.info.customer_name" />
		  <van-cell title-class="tit" title="预算类型" :value="reviewCarData.info.budget_type_name" />
		  <van-cell title-class="tit" title="发起挑战人" :value="reviewCarData.info.challenge_name" />
		  <van-cell title-class="tit" title="客户地址" :value="reviewCarData.info.customer_pos" />
		  <van-cell title-class="tit" title="创建时间" :value="reviewCarData.info.created_at" />
		</van-cell-group>

		<van-cell-group title="提前判定信息" class="group_bd" v-if="reviewCarData.info.status==2">
		  <van-cell title-class="tit" title="提前判定时间" :value="reviewCarData.info.allow_early_determine_time" />
		  <van-cell title-class="tit" title="提前判定原因" :value="reviewCarData.info.allow_determine_reason" />
		</van-cell-group>

		<van-cell-group title="提前判定信息" class="group_bd" v-if="reviewCarData.info.status==3">
		  <van-cell title-class="tit" title="作废时间" :value="reviewCarData.info.cancel_time" />
		  <van-cell title-class="tit" title="作废原因" :value="reviewCarData.info.cancel_reason" />
		</van-cell-group>
			    
	    <div class="review_bottom" v-if="reviewCarData.info.status==1">
	    	<van-cell-group title="提前判定原因" size="large" class="review_status">
				<van-field
				v-model="reason_input"
				rows="2"
				autosize
				type="textarea"
				class="reason_input"
				placeholder="请输入原因"
				/>	
			</van-cell-group>
	    </div>

		<!-- <div class="bottom_btn" v-if="reviewCarData.info.status==1"> -->
		<div class="bottom_btn" v-if="reviewCarData.info.status==1">
			<van-button type="info" @click="auditPass">确认提前判定</van-button>
		</div>
	    
	    
	    
	</div>
</template>


<script>
import { Cell, CellGroup, Field } from 'vant';
import { Tag } from 'vant';	
import { Button } from 'vant';
import { Dialog } from 'vant';

import {sendPost} from '../assets/js/api.js'

export default {
  name: 'reviewCard',
  components: {
  	[Cell.name]: Cell,
  	[CellGroup.name]: CellGroup,
	[Tag.name]: Tag,
	[Field.name]: Field,
	[Button.name]: Button,
	[Dialog.name]: Dialog
  
  },
data (){
    return {
			myIcon: {
				noAvatar: require('../assets/img/icon/icon-803.png')				
			},
		isLoading:false,
		reviewCarData:{
			info:[],
			total:[]
		},
		reason_input:''
    }
  },
  methods:{
	  	reviewCard(){			
				this.reviewCardRequest();
				//this.reviewCardTotal();
		  },
			getQueryString(name){
					var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
					var r = window.location.search.substr(1).match(reg);
					if(r!=null)return  unescape(r[2]); return null;
		   },
		  reviewCardRequest(){
				let _id = this.getQueryString("id");
				let _form = this.getQueryString("urlForm");
				sendPost('api/approval/show',{id:_id}).then(res=>{
					if(res.data.code==0){
						let _user_id = localStorage.getItem("user_id");
						this.reviewCarData.info = res.data.data;
						this.reviewCarData.urlForm = _form;
						// localStorage.setItem("enterprise_name",res.data.data.enterprise_name);
						// localStorage.setItem("accountingAmount",res.data.data.accounting_amount);	
						// localStorage.setItem("kindName",res.data.data.common_card_kind_name);
						// localStorage.setItem("quantityAmount",res.data.data.quantity_and_amount);
						//this.reviewCardTotal(res.data.data.user_id,_user_id)

					}else{
						this.$toast(res.data.msg);
					}					
				});	
		  },
		  reviewCardTotal(a,b){
				sendPost('api/approval/experience/card/countaccountingamount',{applicant:a,auditor:b}).then(res=>{
					console.log(res);
					if(res.data.code==0){
						this.reviewCarData.total = res.data.data;	


					}else{
						this.$toast(res.data.msg);
					}						
				});	
		  },
		  //审核通过
		  auditPass(){
				let _id = this.getQueryString("id");	
				let _this = this;	
				if(!this.reason_input){
					_this.$toast('请输入提前判定原因。');
					return false;
				}		
				function beforeClose(action, done) {
					if (action === 'confirm') {						
						_this.isLoading=true;
						sendPost('api/approval/earlydetermine',{id:_id,allow_determine_reason:_this.reason_input}).then(res=>{
							_this.isLoading=false;	
							done();
							if(res.data.code==0){												
								_this.$router.push({path:'/listTabA'});						
							}else{							
								_this.$ (res.data.msg);	
							}					
						});	
					} else {
						done();
					}
				}

				Dialog.confirm({
					message: '你是否确认提前判定？',
					className:'viaalert',
					beforeClose
				});
				
		  },
  	
	   
  },
  created (){
		
	this.reviewCard()

  }
}	
</script>
<style scoped="">
.bottom_btn{position: fixed; bottom: 0; left: 0; z-index: 199; width: 100%; padding:10px 0; background: #fff;box-shadow:0px 0px 8px 0px rgba(0,0,0,0.1);text-align: center;}
.bottom_btn .van-button{ width: 40%; }


.group_bd .van-cell{ padding: 5px 15px;}
.review_top {border-bottom: 1px solid #ebedf0;margin-bottom: 10px;}
.review_top .title_top{ /*padding-right: 60px;*/ position: relative; }
.review_top .review_status{ position: absolute; top: 13px; right: 15px;}
.review_top .van-cell:not(:last-child)::after{ border-bottom: 0;}
.review_top .review__txt .tit{ flex: auto; width: 60px !important; color: #999;}
.review_top .review__txt .van-cell__value{flex: auto; width: 75% !important; text-align: left; color: #333;}
.review_top .title_top .van-cell--large .van-cell__title{padding-right: 60px;}

.group_bd{ border-bottom: 15px solid #f5f6f9}
.group_bd .van-hairline--top-bottom::after{ border-width:0 0;}
.group_bd .van-cell:not(:last-child)::after{border-bottom:0;}
.group_bd.van-hairline--top-bottom::after{border-width:0 0 !important;}

.group_bd .van-cell__title, 
.group_bd .van-cell__value{ flex:auto !important;}
.group_bd .van-cell__title{ width:60px !important; color: #999;}
.group_bd .van-cell__value{ width: 75% !important; padding-left: 10px; text-align: left; color: #333;}

.van-cell-group__title{ color: #333; font-weight: bold;margin-bottom: 5px;}

.group_bd .van-cell.total{  border-top: 0.5Px solid #efefef; margin-top: 5px; padding: 11px 15px;}
.group_bd .van-cell.total .van-cell__value{ color: #F44545;}

.review .review_bottom{ padding-bottom: 60px; border-top: 0 !important;}
.review_bottom .van-cell-group__title{color: #333;}
.total_h3{ background:#F5F6F9; padding:5px 15px 12px 15px; color:#999; font-size:12px;}
.review_status{ display: flex;}
.review_status .img{flex: 1 auto; margin-left: 20px; margin-top: 15px; width:18px; height: 18px;}

.review_status .status_right{ display: flex; flex: 1 auto; width: 100%;}
.review_status .van-cell__left-icon{ font-size: 26px;}
.review .van-button{line-height: 40px;height:40px;font-size:15px;vertical-align: top;}
.viaalert .van-dialog__content{padding-top:75px;background:url(../assets/img/icon/icon-07.png) 50% 20px no-repeat;background-size:95px auto;}
.reason_input /deep/ textarea{background:#F2F2F2;padding:4px;}

</style>




