<template>	
	<div class="listTab">
		<div class="search_box">
			<form action="/">
			<van-search 
				placeholder="搜索人名、标题、内容、状态、业务编码" 				
				v-model="keyword"
				background="#F5F6F9"
				 @search="onSearch"	
				 show-action				 			 				 
				 @clear="clearKeyWord"				 
				>
					<div slot="action" @click="onSearch">搜索</div>
				</van-search>
			</form>
		</div>

<div class="van-tabs__wrap van-hairline--top-bottom" style="position:relative !important;">
	<div role="tablist" class="van-tabs__nav van-tabs__nav--line myline">
		<div class="van-tab"><span class="van-ellipsis" @click="tabChange('/listTabA')">待处理</span></div>
		<div class="van-tab van-tab--active" @click="tabChange('/listTabB')">
			<span class="van-ellipsis">已处理</span>
			<div class="van-tabs__line" style=" bottom:-1px;width:50%; transform: translateX(50%);  background-color:#307DCD;"></div>
		</div>
		<div class="van-tab" @click="tabChange('/listTabC')"><span class="van-ellipsis">抄送我</span></div>		
	</div>
</div>
		

<van-list
v-model="loading"
:finished="finished"
finished-text="没有更多了"
@load="onLoadPull"
>
<noDataPage v-if="isNoData" :text="noDataTxt" :url="noDataImg"></noDataPage>
<div v-for="(list,id) in listTab" :key="id">
	<div class="item_bd" @click="listToLink(list.type,list.id)" v-if="list.status!=1">
		<div class="h3_tit">
			{{list.title}}
			<van-tag class="h3_tag" plain color="#4EB240" type="primary" v-if="list.status==2">已通过</van-tag>
			<van-tag class="h3_tag" plain color="#F44545" type="success" v-if="list.status==3 && (list.type==2 || list.type==4)">已拒绝</van-tag>
			<van-tag class="h3_tag" plain color="#F00065" type="success" v-if="list.status==4 && list.type==4">已作废</van-tag>
			<van-tag class="h3_tag" plain color="#4EBfff" type="success" v-if="list.status==5 && list.type==4">已过期</van-tag>
			<van-tag class="h3_tag" plain color="#F00065" type="success" v-if="list.status==3 && list.type==3">已作废</van-tag>
		</div>
		<div class="des_p">申请理由：{{list.apply_reason}}</div>
		<div class="des_p">申请描述：{{list.apply_remark}}</div>
		<div class="date_p">{{list.created_at}}</div>		  			
	</div>
</div>


</van-list>
    
	    
		<bottomTabBar activeNum="/listTabB" />


	</div>
</template>


<script>

import { Search } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Tab, Tabs } from 'vant';
import { Tag } from 'vant';	
import {sendPost} from '../assets/js/api.js'
import bottomTabBar from '@/components/bottomTabBar.vue'
import { List } from 'vant';
import noDataPage from '@/components/noDataPage.vue'
//import {listTabFun} from '../assets/js/listTab.js'

//Vue.use(Tab).use(Tabs);


export default {
  name: 'listTabB',
  components: {
	bottomTabBar,noDataPage,
  	[Search.name]: Search,
  	[Tag.name]: Tag,
	  [Tab.name]: Tab,
	  [List.name]: List,
  	[Tabs.name]: Tabs,
  	[Cell.name]: Cell	 
	//[Tabbar.name]: Tabbar    	
	//  ,[TabbarItem.name]: TabbarItem  
  },
  data() {
    return {
		icon: {
			atMe: require('../assets/img/icon/icon-804.png'),//抄送 搜索
			mySearch: require('../assets/img/icon/icon-802.png')       
		},
	  isNoData:false,
	  noDataTxt:"",
	  noDataImg:"",
	  keyword:"",
	  active: 1,
      loading: false,
	  finished: false,
	  countee:1,
	  listTab:[],   
	   listTab1:[],
	   listTab2:[]


    }
  },
//   beforeRouteEnter (to, from, next) {
// 	next(vm=>{
// 		vm.active=from.query.tab ? from.query.tab:0;
// 	});
//   },
  methods: {

  onLoadPull() {
		// 异步更新数据		
			//console.log("this.loading::::::"+this.loading);
			//console.log("this.finished:::::"+this.finished);
			setTimeout(() => {
				this.requestListData(this.active+1,this.keyword,this.countee);
			}, 50);
		},		
	  	onSearch(tab,key_word,page){//搜索事件
			  //console.log(this.active);
			  //console.log(this.keyword);
			  	this.listTab.length = 0;
				this.listTab1.length = 0;
				this.listTab2.length = 0;
				this.countee = 1;
				this.loading = false;
	  			this.finished = false;				
			  setTimeout(() => {
				  //this.requestListData(this.active+1,this.keyword,this.countee);
					this.onLoadPull();
			  }, 20);			  
		},
		onCancel(){//取消搜索
			  console.log("onCancel");
		},
		clearKeyWord(){//清除文字
			  	this.keyword = "";
				this.listTab.length = 0;
				this.listTab1.length = 0;
				this.listTab2.length = 0;
				this.countee = 1;
				this.onLoadPull();
			  //this.requestListData(this.active+1,this.keyword,1);
		},
	  	listToLink(type,id){
			if(type==1){
				this.$router.push({path:'/reviewCard',query:{id:id,tab:this.active}});
			}else if(type==2){
				this.$router.push({path:'/reviewReject',query:{id:id,tab:this.active}});
			}else if(type==3){
				this.$router.push({path:'/earlyDecision',query:{id:id,tab:this.active}});
			}else if(type==4){
				this.$router.push({path:'/keepprice',query:{id:id,tab:this.active}});
			} 			
		  },
		tabChange(url){
			this.$router.push({path:url});
		},
		requestListData(tab,key_word,page){
			sendPost("api/approval/list",{tab:tab,page:page,key_word:key_word}).then(res=>{
				this.isNoData = false;
				let _data = res.data;
				//console.log(res);
				if(_data.code==0){
					//console.log("this.countee:::::"+this.countee);
								//console.log(_data.data.count);
								if(this.countee<=_data.data.count){
									//console.log("rrrr");
									this.listTab = this.listTab.concat(_data.data.item);								
									this.countee = this.countee + 1;
									//console.log("rrrr");
								}else{
									this.finished = true;
								}
								this.loading=false;
								//数据为空是，显示暂无数据组件						
								if(this.countee<=1){
									if(_data.data.item.length>0){
										this.isNoData = false;
									}else{
										this.isNoData = true;
										if(key_word==''){
											this.noDataTxt = "暂无数据";
											this.noDataImg = this.icon.atMe;
										}else{
											this.noDataTxt = "抱歉，暂无搜索结果～";
											this.noDataImg = this.icon.mySearch;											
										}
										setTimeout(() => {
											if(document.getElementsByClassName("van-list__finished-text").length>0){
												for(let i = 0;i<document.getElementsByClassName("van-list__finished-text").length;i++){
														document.getElementsByClassName("van-list__finished-text")[i].innerText = '';
														//console.log(document.getElementsByClassName("van-list__finished-text"));
												}
											}
										 }, 100);

									}	
								}
						//}								 
						
				}else{
					this.$toast(res.data.msg);
				}						
			});
		} 
  },
  created(){
	 // listTabFun.testFun();
	  //this.requestListData(this.active+1,this.keyword,1);	  
  }
}	
</script>
<style scoped>
.search_box{background-color: #F5F6F9;padding: 10px 16px}
.search_box .van-search{ padding: 0 0 !important;}
.search_box .van-search__content{ background: #fff; border-radius: 5px;}
.search_box .van-search{border-radius: 5px;}

.item_bd{margin: 10px 12px;padding: 4px 12px; border-radius: 4px; border: 0.5Px solid #e5e5e5;}
.item_bd .h3_tit{ 
	position: relative;   
    display:block;
    padding:10px 60px 5px 0;
    color: #333;
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;
	font-weight: 600;
    }
    .h3_tag{ position: absolute; top: 10px; right: 0px;}
    .des_p,
    .date_p{    color: #999;
    font-size: 14px;
    height: 26px;
    line-height: 26px; overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;}
	.date_p{ text-align: right; padding-bottom: 5px;}
	.van-tabs__nav--line.myline{padding-bottom:0;}
.van-tabs--line >>> .van-tabs__line{background-color:#307DCD;}


</style>




