<template>	
	<div class="review">
		<div class="review_top">
			<div class="title_top">
				<van-cell size="large" center title="我的特批审批单" :icon="avatar" />
			</div>
		</div>
		<van-cell-group class="group_bd">
		  <van-cell title-class="tit" title="客户名称"  v-model="formCon.username" />
		  <van-cell title-class="tit" title="预算类型" v-model="formCon.budgettype" />
		  <van-cell title-class="tit" title="卡种所属大类" v-model="formCon.cardspecies" />
		  <van-cell title-class="tit" title="卡种是否定制" v-model="formCon.customized_card_kind" />
		  <van-cell title-class="tit" title="选择卡种" v-show="formParams.customized_card_kind==2"  v-model="formCon.cardtype"  />
		  <van-cell title-class="tit" title="申请产品" v-show="formParams.customized_card_kind==1"  v-model="formParams.apply_product"  />
		  <van-cell title-class="tit" title="单价" :value="'¥'+formParams.unit_price" />
		  <van-cell title-class="tit" title="数量" v-model="formParams.num" />
		  <van-cell title-class="tit" title="采购预算" :value="'¥'+formParams.purchase_budget" />
		  <van-cell title-class="tit" title="配赠比例/折扣" v-model="formCon.sales_way_val" />
		  <van-cell title-class="tit" title="是否有溢价佣金" v-model="formCon.is_kickback" />
		  <van-cell title-class="tit" title="溢价佣金" v-show="formParams.is_kickback==1"  :value="'¥'+formParams.kickback"  />
		  <van-cell title-class="tit" title="除去溢价佣金后单价" v-show="formParams.is_kickback==1"  :value="'¥'+formParams.kickback_unit_price"  />
		  <van-cell title-class="tit" title="是否符合量价挂钩" v-model="formCon.is_price_indexed" />
		  <van-cell title-class="tit" title="特批内容" v-model="formCon.rules" />
		  <van-cell title-class="tit" title="审批原因" v-model="formParams.approval_reason" />
		</van-cell-group>	
		<div class="bottom_btn">
			<van-button plain hairline type="info" @click="back">返回修改</van-button>
			<van-button class="right_btn" type="info" @click="subParams" :disabled="!isFirst">确认提交</van-button>
		</div>
	    
	</div>
</template>


<script>
import { Cell, CellGroup ,Button ,Toast} from 'vant';
import {sendPost,promptMsg,passMsg} from '../assets/js/api.js'
export default {
  name: 'preview',
  components: {
  	[Cell.name]: Cell,
  	[CellGroup.name]: CellGroup,
	[Button.name]: Button,
	[Toast.name]: Toast
  },
data (){
    return {
		formParams:{},
		formCon:{},
		avatar:'',
		isFirst:true
    }
  },
  methods:{
	  subParams: function(e){
		  let resFun;
		  if(this.isFirst){
			this.isFirst = false;
		  }else{
			e.preventDefault();
		  }
		  if(this.formParams.id){
			resFun=sendPost('api/special/sales/apply/update',this.formParams);
		  }else{
			resFun=sendPost('api/special/sales/apply/store',this.formParams);
		  }
		  resFun.then(res=>{
			let resData = res.data;		
			if(resData.code == 0){
				passMsg('提交审批单成功！');
				this.$router.push('/mySubmit');
				localStorage.removeItem('formData');
			}
			if(resData.code == 1010025){
				promptMsg('操作失败！');
				this.isFirst = true;
			}
			if(resData.code == 1010001){
				promptMsg(resData.msg[0]);
				
			}
		});
	  },
	  back:function(){
		  this.$router.go(-1);
	  }
  },
  created (){
	  this.formParams = JSON.parse(localStorage.getItem('formData')).data;
	  this.formCon = JSON.parse(localStorage.getItem('formData')).con;
	  this.avatar = localStorage.getItem('avatar') ? localStorage.getItem('avatar') : 'manager-o';
  }
}
</script>
<style scoped="">
.review{padding-bottom:70px;}
.bottom_btn{position: fixed; bottom: 0; left: 0; z-index: 9999; width: 100%; padding:10px 0; background: #fff;box-shadow:0px 0px 8px 0px rgba(0,0,0,0.1);}
.bottom_btn .van-button{ width: 40%; margin-left: 15px;}
.bottom_btn .van-button.right_btn{ float:right; margin-right: 15px;}

.group_bd .van-cell{ padding: 5px 15px;}
.review_top {border-bottom: 1px solid #ebedf0;margin-bottom: 10px;}
.review_top .title_top{ padding-right: 60px; position: relative; }
.review_top .review_status{ position: absolute; top: 13px; right: 15px;}
.review_top .van-cell:not(:last-child)::after{ border-bottom: 0;}
.review_top .review__txt .tit{ flex: auto; width: 60px !important; color: #999;}
.review_top .review__txt .van-cell__value{flex: auto; width: 75% !important; text-align: left;}
.group_bd .van-hairline--top-bottom::after{ border-width:0 0;}
.group_bd .van-cell:not(:last-child)::after{border-bottom:0;}
.group_bd.van-hairline--top-bottom::after{border-width:0 0 !important;}

.group_bd .van-cell__title, 
.group_bd .van-cell__value{ flex:auto !important;}
.group_bd .van-cell__title{ width:60px !important; color: #999;}
.group_bd .van-cell__value{ width: 75% !important; padding-left: 10px; text-align: left; color: #333;}

.van-cell-group__title{ color: #333; font-weight: bold;margin-bottom: 5px;}

.group_bd .van-cell.total{ border-top: 1px solid #ccc; margin-top: 5px; padding: 11px 15px;}
.group_bd .van-cell.total .van-cell__value{ color: #F44545;}
.review_bottom .van-cell-group__title{color: #333;}
.total_h3{ background:#F5F6F9; padding:5px 15px 12px 15px; color:#666; font-size:14px;}

</style>




