<template>
  <div class="oa-list">
    <div class="list-box">
    	<div class="list-item" v-for="item in items" :key="item.id">
    		<van-tag color="#D8D8D8">{{item.updated_at}}</van-tag>
    		<van-panel>
    			 <!-- <a v-bind:href="{{item.url}}"> -->
				<!-- <router-link to="/{{item.url}}">					 -->
					<router-link :to="{path:item.url}" >
					  <h3>
						  <span class="is_read" v-if="item.is_read==2"></span>
						  {{item.title}}
					  </h3>
					  
					  <p class="p1" v-html="item.content"></p>
					  <!-- <p class="p2" >{{item.updated_at}}</p> -->
					  <p class="p2"></p>
					</router-link>
					  <van-cell title="详情"  is-link right-icon :url="item.url" />					  
				<!-- </router-link> -->
    			 <!-- </a>  -->
			</van-panel>
    	</div>




<!-- <div class="list-item">
    		<van-tag color="#D8D8D8">06-27 16:40</van-tag>
    		<van-panel>
    			<a href="/">
					  <h3>[OA审批申请] 您有新的审批事项</h3>
					  <p class="p1">您好，您申请的卡面，卡面编号为【0979870】，已制作好，您可以下单使用了！</p>
					  <p class="p2">2019-06-25 18:50</p>
					  <van-cell title="详情" is-link right-icon />
				  </a>
				</van-panel>
    	</div> -->

    

    </div>
    <van-tabbar fixed>
		  <van-button type="default" url="/index" block >进入系统</van-button>
		</van-tabbar>
  </div>
</template>

<script>
import { Cell, CellGroup, Row, Col , Panel , Tabbar , Button , Tag } from 'vant';
import {sendPost} from '../assets/js/api.js'

export default {
  name: 'home',
  components: {
  	[Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Panel.name]: Panel,
    [Tabbar.name]: Tabbar,
    [Button.name]: Button,
    [Tag.name]: Tag
  },
  data (){
    return {
		items:[]
    }
  },
  methods: {
			requestList:function(){
				
				console.log("requestList");
				console.log(this.items);
				let thisItems = this.items;
				sendPost('api/notify/send/record/list').then(res=>{
				    		console.log(res);
				    		let _data = res.data;
				    		if(_data.code==0){
								console.log(_data);
								console.log("item");
								console.log(_data.data.item);
								 for(let i=0;i<_data.data.item.length;i++){
									 thisItems.push(_data.data.item[i]);
								 }
								console.log("type");
								console.log(thisItems);
							}else{
								this.$toast(_data.msg);
							}				    		
				  	});
			}
  },
  created:function(){		
		this.requestList();
  	
  }
}
</script>
<style>
.oa-list{
	background: #EBEBEB;
	min-height:100vh;
	padding-bottom: 60px;;
}
.list-item{
	padding:10Px 12Px 0;
	text-align: center;
}
.list-item .van-tag{
	color:#fff;
	margin-bottom:10px;
	border-radius: 6px;;
}
.list-item .van-tag::after{
	border:0;
}
.list-item .van-cell-group{
	border-radius:4px;
	text-align: left;
	padding:12px 15px;
}
.list-item .van-cell-group > .van-cell{
	display: none;
}
.list-item .van-cell-group h3{
	margin:0;
	font-size: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color:#333;
}
.list-item .van-cell-group div .van-cell{
	font-size: 16px;
	padding:10px 0 0;
	border-top:1px solid #E5E5E5;
	margin-top:10px;
	color:#666;
}
.list-item .van-cell-group .p1{
	margin:10px 0;
	font-size:14px;
	height:34Px;
	line-height:17Px;
	overflow:hidden;
	color:#666;
}
.list-item .van-cell-group .p2{
	margin:0;
	font-size:12px;
	color:#999;
}
.oa-list .van-button{
	border:0;
	line-height: 50px;
	font-size:15px;
}
.oa-list .is_read{display:inline-block; width:8px; height:8px; border-radius:100%; background:#F44545;}
</style>
