<template>	

	<div class="collarCard review">

		<div class="review_top">
			<div v-if="isBD">
				<van-cell size="large" center title="确认领卡" :icon="avatar" />
				<van-tag class="review_status" plain color="#307DCD">{{cardDetail.card_recept_status_desc}}</van-tag>
			</div>
			<div v-if="isCardRecept">
				<van-cell size="large" center title="确认出库" :icon="avatar" />
				<van-tag class="review_status" plain color="#307DCD">{{cardDetail.card_delivery_status_desc}}</van-tag>
			</div>
		</div>
		
		<van-cell-group title="" class="group_bd">
			<van-cell title="领卡单号：" :value="cardDetail.id" />
			<van-cell title="客户名称：" :value="cardDetail.enterprise_name" />
			<van-cell title="业务类型：" :value="cardDetail.type" />
			<van-cell title="项目名称：" :value="cardDetail.project_name" />
			<van-cell title="业务编号：" :value="cardDetail.business_no" />
			<van-cell title="卡种名称：" :value="cardDetail.card_kind_name" />
			<van-cell title="卡数量：" :value="cardDetail.card_num" />
      <van-cell title="卡段：">
        <template slot="default">
			<div v-for="(item, index) in cardDetail.card_string" :key="index">{{item}}</div>
        </template>
			</van-cell>
      <van-cell title="有效期：" :value="cardDetail.expiry_date" />
			<van-cell v-if="isCardRecept" title="申请人：" :value="cardDetail.applicant" />
			<van-cell v-if="cardDetail.card_kind_category_code == 'HEALTH_SERVICE_CARD'" title="使用场景：" :value="cardDetail.use_scene" />
			<div v-if="cardDetail.card_kind_category_code == 'HEALTH_SERVICE_CARD' && cardDetail.isEnterpriseCard == true" class="card">
				企业卡无实体卡，点击【确认领卡】激活后，请联系【体检增值服务运营】，进行相应的服务的【兑换发放】。
			</div>
		</van-cell-group>

    <div class="fotter">
      <van-button type="info" v-if="isBD && cardDetail.card_recept_status==1 && userId == cardDetail.contract_sales" @click="confirmShow">确认领卡</van-button>
			<van-button type="info" v-if="isCardRecept && cardDetail.card_delivery_status==1" @click="confirmShow">确认出库</van-button>
    </div>

		<van-dialog 
      class="confirmModal" 
      v-model="isShowModal"
			:beforeClose="beforeClose"
      confirm-button-color="#307DCD" 
      show-cancel-button 
      width="80%">
      <div class="inner">
        <img src="../assets/passIcon.png">
        <p v-if="isBD"> 您确认已领卡？</p>
		<p v-if="isCardRecept"> 您确认已出库？</p>
      </div>
    </van-dialog>
	    
	</div>
</template>


<script>
import { Cell, CellGroup, Tag, Tabbar, Button, Dialog} from 'vant';	
import {sendPost} from '../assets/js/api.js'

export default {
  name: 'collarCard',
  components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Tag.name]: Tag,
		[Tabbar.name]: Tabbar,
		[Button.name]: Button,
		[Dialog.Component.name]: Dialog.Component,
  },
  data (){
    return {
		myIcon: {
			noAvatar: require('../assets/img/icon/icon-803.png')				
		},
		cardDetail: {},
		avatar:'',
		isSales: 1,	// 默认销售
		isShowModal: false,
		isCardRecept:false,
		isBD:false,
    }
  },
//   computed:{
// 	isCardRecept: function(){
// 		return 
// 	},
// 	isBD: function(){
// 		return localStorage.getItem('slug').split(',').includes('h5_cardRecept_isBD') && this.cardDetail.is_collect_card
// 	}
//   },
  watch:{
	  cardDetail(newVal,oldVal){
		  this.isCardRecept = localStorage.getItem('slug').split(',').includes('h5_cardRecept_confirmDeliveryCard') && !newVal.is_collect_card;
		  this.isBD = localStorage.getItem('slug').split(',').includes('h5_cardRecept_isBD') && newVal.is_collect_card;
	  }
  },
  created (){
		this.isSales = localStorage.getItem('isSales');
		this.role = localStorage.getItem('role');
		this.avatar = localStorage.getItem('avatar') ? localStorage.getItem('avatar') : this.myIcon.noAvatar;
		this.userId = localStorage.getItem('user_id');
		this.getDetail();
  },
  methods: {
		getDetail() {
			sendPost('api/recept/card/detail',{
				id:this.$route.query.id,
				sendRecord:this.$route.query.send_record
			}).then(res=>{
				let resData = res.data;
				if(resData.code == 0){
					this.cardDetail = resData.data.item;
				}else{
					this.$toast(res.data.msg);
				}	
			});
		},
		confirmShow() {
			this.isShowModal = true;
		},
		beforeClose(action, done) {
			if(action === 'confirm') {
				this.receptOrdelivery();
    		setTimeout(done, 1000);
			}
			
			if(action === 'cancel') {
				done();
			}
		},
		receptOrdelivery() {
			if(	this.isBD){
				sendPost('api/recept/card/confirmrecept',{id: this.cardDetail.id}).then(res=>{
					let resData = res.data;
					if(resData.code == 0){
						this.getDetail();
					}else{
						this.$toast(res.data.msg);
					}	
				});
			}else if(this.isCardRecept){
				sendPost('api/recept/card/confirmdelivery',{id: this.cardDetail.id}).then(res=>{
					let resData = res.data;
					if(resData.code == 0){
						this.getDetail();
					}else{
						this.$toast(res.data.msg);
					}	
				});
			}
		}
  }
}	
</script>

<style scoped>
.group_bd .van-cell__title{
  width: 2.0rem !important;
}
.group_bd{
	padding-bottom: 70px;
}
.fotter {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
	padding-bottom: 15px;
	background-color: #fff;
}
.fotter button{
	width: 90%;
}
.confirmModal .inner{
	text-align: center;
	padding: 30px 20px 0;
}
.confirmModal .inner img{
	width: 95px;
	height: 72px;
}
.card {
	margin: 15px;
	padding: 10px;
	background: #FAFAFA;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}
</style>





