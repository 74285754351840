import Vue from 'vue'
import Router from 'vue-router'
import {sendPost,clearInit,promptMsg} from './assets/js/api.js'
import store from './store'
import dd from 'dingtalk-jsapi'

import Home from './views/Home.vue'
import Index from './views/index.vue'
import List from './views/list.vue'
import Approval from './views/approval.vue'
import Form from './views/form.vue'
import refuse from './views/refuse.vue'
import review from './views/review.vue'
import reviewReject from './views/reviewReject.vue'
import Preview from './views/formpreview.vue'
import KeepPrice from './views/keepPrice.vue'







import reviewRejectCard from './views/reviewRejectCard.vue'
import reviewCard from './views/reviewCard.vue'
import earlyDecision from './views/earlyDecision.vue'
import messageDetail from './views/messageDetail.vue'
import collarCard from './views/collarCard.vue'
import listTab from './views/listTab.vue'
import listTabA from './views/listTabA.vue'
import listTabB from './views/listTabB.vue'
import listTabC from './views/listTabC.vue'

import monthRecord from './views/monthRecord.vue'
import mySubmitReview from './views/mySubmitReview.vue'

import mySubmitDetail from './views/mySubmitDetail.vue'
import mySubmit from './views/mySubmit.vue'
import searchList from './views/searchList.vue'

import cardReceiptList from './views/cardReceiptList.vue'
import cardReceiptDetail from './views/cardReceiptDetail.vue'




Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: "/index"
    },
    {
      path: '/index',
      name: 'index',
      component: Index
    },
    {
      path: '/list',
      name: 'list',
      component: List
    },
    {
      path: '/approval',
      name: 'approval',
      meta:{title:"OA审批"},
      component: Approval
    },
    {
      path: '/form',
      name: 'form',
      meta:{title:"申请特批单"},
      component: Form
    },
    {
      path: '/form/preview',
      name: 'preview',
      meta:{title:"申请特批单确认"},
      component: Preview
    },
    {
      path: '/refuse',
      name: 'refuse',
      meta:{title:"拒绝原因"},
      component: refuse
    },
    {path:'/review',name: 'review', meta:{title:"申请特批单详情"},component: review}, 
    {path:'/keepprice',name:'keepprice', meta:{title:"OA审批"},component:KeepPrice}, 
    {path:'/reviewReject',name: 'reviewReject', meta:{title:"OA审批"},component: reviewReject},   
    {path:'/earlyDecision',name: 'earlyDecision', meta:{title:"OA审批"},component: earlyDecision},  
     
    
    
    
    
    
    
    
    
    
    
    
    {path:'/reviewCard',name: 'reviewCard', meta:{title:"OA审批"},component: reviewCard},  
    {path:'/reviewRejectCard',name: 'reviewRejectCard',component: reviewRejectCard},
    {path:'/messageDetail',name: 'messageDetail',component: messageDetail},      
    {path:'/collarCard',name: 'collarCard',component: collarCard},
    {path:'/listTab',name: 'listTab',component: listTab},    
    {path:'/listTabA',name: 'listTabA',meta:{title:"OA审批"},component: listTabA}, 
    {path:'/listTabB',name: 'listTabB',meta:{title:"OA审批"},component: listTabB}, 
    {path:'/listTabC',name: 'listTabC',meta:{title:"OA审批"},component: listTabC}, 


    {path:'/monthRecord',name: 'monthRecord', meta:{title:"体验卡通过记录"},component: monthRecord}, 
    {path:'/mySubmit',name: 'mySubmit', meta:{title:"OA审批"},component: mySubmit}, 
    {path:'/searchList',name: 'searchList',component: searchList}, 
    {path:'/mySubmitDetail',name: 'mySubmitDetail', meta:{title:"申请体验卡详情"},component: mySubmitDetail}, 
    {path:'/mySubmitReview',name: 'mySubmitReview',component: mySubmitReview},    
    // {path:'/mySubmitReview',name: 'mySubmitReview',component: mySubmitReview}, 
    // {path:'/mySubmitReview',name: 'mySubmitReview',component: mySubmitReview},    
    // {path:'/mySubmitReview',name: 'mySubmitReview',component: mySubmitReview},    
    // {path:'/mySubmitReview',name: 'mySubmitReview',component: mySubmitReview},    
    // {path:'/mySubmitReview',name: 'mySubmitReview',component: mySubmitReview}, 


    {path:'/cardReceiptList',name: 'cardReceiptList',meta:{title:"卡签收列表"}, component: cardReceiptList}, 
    {path:'/cardReceiptDetail',name: 'cardReceiptDetail',meta:{title:"合同卡详情"},component: cardReceiptDetail}, 
    
    
    {path: '/a',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {path:'/*', name: 'home', redirect: '/index'}, 
  ],
  scrollBehavior(){
    return {
      x:0,
      y:0
    }
  }
});

// router.afterEach((to,from)=>{
//   store.commit('updateLoading', false);  
// });
// router.beforeEach((to,from,next)=>{
//   let { query } = to;
//   let { code } = query;
//   if(!code){
//     console.log(code+'1');
//   }else{
//     console.log(code+'2');
//   }
//   alert(code)
//   next();
// });

export const platformName = getPlatformName()
if(process.env.NODE_ENV != 'development'){
  router.beforeEach((to, from, next) => {
  
    let { query } = to;
    let { code } = query;
    let baseUrl=process.env.VUE_APP_ROUTER_URL;
    let egp = 90*60*1000;
    let curTime = new Date().getTime();
    let localTime = Number(localStorage.getItem('time'));
    
     /*路由发生改变修改页面的title */
     //console.log(to.meta.title);
    if(to.meta.title) {
      let title = to.query.title ? to.query.title : to.meta.title;
      document.title = title;
    }

    function login() {
      sendPost('api/user/login',{'code':code}).then(function(res){
        let resData = res.data;
        if(resData.code == 0){
          localStorage.setItem('token',res.data.data.token);
          localStorage.setItem('name',res.data.data.name);
          localStorage.setItem('role',res.data.data.role);
          localStorage.setItem('isSales',res.data.data.isSales);
          localStorage.setItem('user_id',res.data.data.user_id);
          localStorage.setItem('avatar',res.data.data.avatar);
          localStorage.setItem('slug',res.data.data.slug);
          localStorage.setItem('time',curTime);
          next();
          // store.commit('updateLoading', false);
          location.reload();
        }else{
          promptMsg(resData.msg);
        }
      });
    }
    
    if(!localStorage.getItem('token') || (curTime - localTime > egp)){
      clearInit();
      if(!code){
        const ssoRedirect = encodeURI(
        `${process.env.VUE_APP_SSO_URL}/callback/dingtalk?tag=crm@@${encodeURIComponent(baseUrl+to.fullPath)}`
        )
        if (dd.env.platform === 'notInDingTalk') {
          if (platformName === 'wecom') {
            
            let url='https://open.weixin.qq.com/connect/oauth2/authorize?appid='+process.env.VUE_APP_APP_ID+'&redirect_uri='+encodeURIComponent(baseUrl+to.fullPath)+'&response_type=code&scope=snsapi_userinfo&agentid='+process.env.VUE_APP_AGENT_ID+'#wechat_redirect';
            location.href=url;
            return 
          }
          else {
            if (document.cookie.indexOf(process.env.VUE_APP_COOKIE_KEY) === -1) {
              location.href = process.env.VUE_APP_SSO_URL
              // next()
            } else {
              login()
            }
          }
        } else { // in dingtalk
          const ssoRedirect = 
            `${process.env.VUE_APP_SSO_URL}/callback/dingtalk?tag=crm@@${encodeURIComponent(baseUrl+to.fullPath)}`
          if (document.cookie.indexOf(process.env.VUE_APP_COOKIE_KEY) === -1) {
            dd.ready(function() {
              dd.runtime.permission.requestAuthCode( {
                corpId: process.env.VUE_APP_DINGTALK_CORP_ID,
              }).then(info => {
                window.location.href = ssoRedirect + '&code=' + info.code
              }).catch(e => {
                console.log('auth error in dingtalk', e)
              })
            })
            dd.error((err) => {
              console.log('dd error: ', err)
            })
          } else {
            login()
          }
        }
        // }
      }else{
        login()
      }
    }else{
      store.commit('updateLoading', false);
      next();
    }
  });
}


export default router;

export function getPlatformName() {
  const ua = window.navigator.userAgent.toLowerCase();
  console.log('ua: ', ua)
  if (/micromessenger/.test(ua) && /wxwork/.test(ua)) {
    return 'wecom'
  }
  if (/dingtalk/.test(ua)) {
    return 'dingtalk'
  }
  
  return 'h5'
}

