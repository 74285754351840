<template>
  <div id="app">
    <div class="loadbox" v-if="showLoading">
      <!-- <van-loading type="spinner" /> -->
      <van-skeleton title :row="10" />
    </div>
    <router-view/>
  </div>
</template>
<script>
import { Loading , Skeleton } from 'vant';
import { mapState } from 'vuex';

export default {
  name: 'app',
  components: {
    [Loading.name]: Loading,
    [Skeleton.name]: Skeleton
  },
  computed: {
    ...mapState({
      showLoading:(state) => state.showLoading
    })
  },
  data(){
    return {
      loading:'',
    }
  },
  created(){
    // setTimeout(()=>{
    //   this.loading=this.showLoading;
    // },500);
  }
  
}
</script>
<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.loadbox{
  height:100vh;
  width:100vw;
  position: fixed;
  top:0;
  left:0;
  background: #fff;
  /* background:rgb(0,0,0,0.6); */
  z-index: 9999;
}
.loadbox .van-loading{
  width: 40px;
  height: 40px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);

}
.van-overlay,body .van-toast{
	background:rgba(0,0,0,0.5);
}
body{
  min-height:100vh;
}
@media only screen and (width: 375px) and (min-height: 690px){
  .van-tabbar--fixed,.van-popup{
    padding-bottom: 34px;
  }
 }
</style>
