<template>	
	<div class="review">
		<div class="review_top">	
			<van-cell class="large50" :title="msgDetail.title" size="large" center :icon="avatar" />					
			<div class="message_text" v-html=" msgDetail.content "></div>			 
		</div>

		<!-- <div class="message_text2">
			<p>卡面编号：123456</p>
			<p>客户名称：广州哈哈哈有限公司</p>
			<p>有效期限：2020-12-31</p>
		</div>  -->

	</div>
</template>


<script>
import { Cell, CellGroup } from 'vant';
import { Tag } from 'vant';	
import axios from 'axios'
import Vue from 'vue';
import Vuex from 'vuex';
import {sendPost} from '../assets/js/api.js'
import { setTimeout } from 'timers';

export default {
  name: 'messageDetail',
  components: {
  	[Cell.name]: Cell,
  	[CellGroup.name]: CellGroup,
  	[Tag.name]: Tag
  
  },
  data (){
    return {
		avatar:"",
		msgDetail:[]
    }
  },
methods: {
			getQueryString(name){
					var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
					var r = window.location.search.substr(1).match(reg);
					if(r!=null)return  unescape(r[2]); return null;
		   },
		 msgDetailFun(){
			 
		 		console.log("msgDetail");
				 //console.log(this.msgDetail);
				 let _this = this;
				 let thisMsg = _this.msgDetail;
				//sendPost('api/notify/send/record/show?id='+_this.getQueryString("id")).then(function(res){ 
		 		sendPost('api/notify/send/record/show',{id:this.$route.query.id,send_record:this.$route.query.send_record}).then(function(res){
		 		    		console.log(res);
							 let _data = res.data.data;
		 		    		if(res.data.code==0){								
								_this.msgDetail = _data;
								//console.log(_this.msgDetail.title);
								//thisMsg.push({title:res.data.data.title,content:res.data.data.content});						
							}
		 		  	});
		 	}		
	},
	created: function () {
			if (!localStorage.getItem('token')) {
			sendPost('api/test/login',{'user_id':206}).then(function(res){
				console.log(res);
				localStorage.setItem('token', res.data.data.token);
				
				
			});
			}

			this.msgDetailFun();
			this.avatar=localStorage.getItem('avatar');
				
					
	}
	
}	



</script>
<style scoped>
.group_bd .van-cell{ padding: 5px 15px;}
.review_top { padding-right: 0; border-bottom: 1px solid #ebedf0;margin-bottom: 10px;}
.review_top .title_top{ padding-right: 60px; position: relative; }
.review_top .review_status{ position: absolute; top: 13px; right: 15px;}
.review_top .van-cell:not(:last-child)::after{ border-bottom: 0;}
.review_top .review__txt .tit{ flex: auto; width: 60px !important; color: #999;}
.review_top .review__txt .van-cell__value{flex: auto; width: 75% !important; text-align: left;}

.message_text{ color: #666; font-size: 14px; padding: 0 15px 15px 15px; }
.message_text2{ color: #333; font-size: 14px; padding: 0 15px 15px 15px;}
/*50*50*/
.large50{ }
.large50 .van-icon__image{width: 50px; height: 50px;}
.large50 .van-cell__title{ font-weight: bold;}
.large50 .van-cell__left-icon{ height: 50px; line-height: 50px;}
.large50 .van-image__img{ width: 50px; height: 50px;}


</style>




