<template>	
	<div class="refuse">
		
		<van-cell-group title=" " class="group_bd" v-if="refuse.type==2">
		  <van-cell title-class="tit" title="客户名称" :value="this.info.name" />
		  <van-cell title-class="tit" title="产品详情" :value="this.info.kindName+'；'+this.info.quantityAmount" />
		  <van-cell title-class="tit" title="核算金额" class="accounting" :value="'¥'+this.info.accountingAmount" />
		  <!-- <van-cell title-class="tit" title="申请理由" :value="this.info.reason" /> -->
		</van-cell-group>

		<van-cell-group title=" " class="group_bd" v-if="refuse.type==4">
		  <van-cell title-class="tit" title="总部" :value="detaillCon.enterprise_headquarters_name" />
		  <van-cell title-class="tit" title="守价产品" :value="detaillCon.product_name" />
		  <van-cell title-class="tit" title="最高配赠" v-if="detaillCon.max_allocation_ratio!='0.00'" :value="detaillCon.max_allocation_ratio+'%'" />
		  <van-cell title-class="tit" title="最低单价" v-if="detaillCon.min_price!='0.00'" :value="detaillCon.min_price+'元'" />
		</van-cell-group>

	    <van-cell-group>
					  <van-field
					  	v-model="refuse.message"					   
						@keyup="keyUp()"  
					    type="textarea"
					    placeholder="请输入拒绝原因"
					    rows="6"
					    autosize
					  />
			</van-cell-group>
			
			<van-cell title=" " value-class="num-txt" :value="refuse.keyWordAmount+'/'+refuse.keyWordMax" />
		
			<div class="tag-txt">快捷输入</div>

			<div class="tag_list" v-if="refuse.type==2">
				<van-tag round class="tag_item" v-for="item in refuse.quickKeyWord.card" v-bind:key="item" v-on:click="addKeyWord(item)">{{item}}</van-tag>	
			</div>
			<div class="tag_list" v-else>
					<van-tag round class="tag_item" v-for="item in refuse.quickKeyWord.approval" v-bind:key="item" v-on:click="addKeyWord(item)">{{item}}</van-tag>				
			</div>
			
			<van-tabbar>
				
				<van-button type="info" v-if="isLoading" loading loading-text="提交中..." square>确认拒绝</van-button>
				<van-button type="info" v-else  @click="submitRefuse" square>确认拒绝</van-button>
			</van-tabbar>
	</div>
</template>


<script>
import { Field } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Tag } from 'vant';	
import { Tabbar } from 'vant';
import { Button } from 'vant';
import { Toast } from 'vant';
import { setTimeout } from 'timers';
import {sendPost} from '../assets/js/api.js'


export default {
  name: 'refuse',
  components: {
  	[Cell.name]: Cell,
  	[CellGroup.name]: CellGroup,
  	[Tag.name]: Tag,
  	[Tabbar.name]: Tabbar,
  	[Button.name]: Button,
  	[Toast.name]: Toast,
    [Field.name]: Field
  },
  data(){
	  return {
		icon: {
			infoImg: require('../assets/img/icon/icon-04.png'),//!
			mySearch: require('../assets/img/icon/icon-802.png')       
		},
		isLoading:false,
		info:{
			name:localStorage.getItem("enterprise_name"),
			accountingAmount:localStorage.getItem("accountingAmount"),
			kindName:localStorage.getItem("kindName"),
			quantityAmount:localStorage.getItem("quantityAmount")
		},
		refuse:{
			//1:审批  2:体验卡
			//type:1,
			quickKeyWord:{
				//审批---快捷输入关键词
				card:["申请人要求取消","数量有误","填写信息错误","更换产品","额度不够","金额过大","申请频率过于频繁"],
				//体验卡---快捷输入关键词
				approval:["不通过","单价太低","配赠太高","折扣太多","采购预算少","溢价佣金过多"]
			},
			keyWordAmount:0,
			keyWordMax:256,
			message:""
		},
		detaillCon:{},
	  }
  },
  methods: {
  	
  	lengthCheck:function(){
	  		Toast({
				  	message: '字数超过上限哦',
				  	icon: this.icon.infoImg
				});
	  },
	  keyUp:function(){
		  let _this = this;
		  let _curText = _this.refuse.message;
		  
		  console.log(_this.refuse.message);
		  if(_this.refuse.message.length>_this.refuse.keyWordMax){
			 setTimeout(() => {
				  _this.refuse.message = _curText.substring(0,_this.refuse.keyWordMax);
			 }, 100);
			  return false;
			}else{
				_this.refuse.keyWordAmount = _this.refuse.message.length;
			}	

	  },
	  addKeyWord:function(v){		  	
			let _this = this;
			let _curText = _this.refuse.message;
			console.log(v);
			console.log(v.length);			
			if(_curText.length>=0&&(_curText.length+v.length)<_this.refuse.keyWordMax){
				if(_curText.length==0){
					_this.refuse.message = v;
				}else{
					_this.refuse.message = _curText + "," + v;
				}
			}else{
				this.lengthCheck();
			}
			this.keyUp();			
	  },
		getQueryString(name){
			var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
			var r = window.location.search.substr(1).match(reg);
			if(r!=null)return  unescape(r[2]); return null;
		},
	  submitRefuse(event){
		  let _this = this;
		  let _curText = _this.refuse.message;
		  if(_curText.length==0){
			  	Toast({
				  	message: '请输入拒绝原因',
				  	icon: this.icon.infoImg
				});
		  }else{
			   let _id = this.getQueryString("id");
				  console.log(_this);
				  //console.log(event.disabled);
				   _this.isLoading=true;				  
			   if(this.refuse.type==2){				 
				   console.log("拒绝体验卡");				  
					sendPost('api/approval/experience/card/audit/reject',{id:_id,reject_reason:_curText}).then(res=>{
						 	_this.isLoading=false;
							console.log(res);
							if(res.data.code==0){					
									//Toast({message: res.data.msg});
									this.$router.push({
										path: "listTabA"
									})
							}else{
									Toast({message: res.data.msg});
							}					
						});	
				}else if(this.refuse.type==1){
					console.log("拒绝特批单");
					//api/special/sales/apply/audit
					sendPost('api/special/sales/apply/audit',{id:_id,reject_reason:_curText,status:3}).then(res=>{
							 _this.isLoading=false;
							console.log(res);
							if(res.data.code==0){					
									//Toast({message: res.data.msg});
									this.$router.push({
										path: "listTabA"
									})
							}else{
									Toast({message: res.msg	});
									
							}					
						});	
				}else if(this.refuse.type==4){
					sendPost('api/approval/guardpriceapproval',{id:_id,refuse_reason:_curText,result:2}).then(res=>{
							 _this.isLoading=false;
							if(res.data.code==0){					
								//Toast({message: res.data.msg});
								this.$router.push({
									path: "listTabA"
								})
							}else{
								Toast({message: res.msg	});
									
							}					
						});	
				}
		  }
	  },
	  detailFun(){
		  sendPost('api/approval/guardpricedetail',{id:this.$route.query.id}).then(res=>{
			let resData = res.data;
			if(resData.code == 0){
				this.detaillCon = resData.data;
			}else{
				this.$toast(res.data.msg);
			}	
		});
	  }
	   
  },
  created(){
		  let _type = this.getQueryString("type");
		  this.refuse.type=_type;
		  if(_type == 4) this.detailFun();
  }
}	
</script>
<style>
	.refuse .van-cell:not(:last-child)::after{ right:15px;}
	.refuse .van-hairline--top-bottom::after{border-width:0 0;}
	.refuse .num-txt{ color: #999;}
	.refuse .tag-txt{ color: #999; font-size: 14px; line-height: 24px; padding: 10px 15px;}
	.tag_item{ padding: 5px 10px; margin-bottom: 5px; margin-right: 8px; background-color: #f0f0f0 !important; color: #666 !important;}
	.tag_list{ margin: 0 15px;}
	.van-button{ border-radius:4px; width: 90%; margin: 0 auto;}
	.refuse .van-cell-group__title{ color: #333;}
	.van-toast{ width: 130px;}
	/* .accounting .van-cell__value{ color: #F44545 !important;} */
</style>




